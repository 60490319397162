import axios from 'axios';
import React, { useState, useEffect } from 'react'
import Helmet from 'react-helmet';
import { toast } from 'react-toastify';
import Navbar from '../../components/navbar/Navbar';
import Topbar from '../../components/topbar/Topbar';
import Footer from '../footer/Footer';
import "./faq.css";

const Faq = () => {
    const [faq, setFaq] = useState([]);
    useEffect(() => {
        getFaq()
    }, []);
    const getFaq = async () =>{
        try {
            const faqresponse = await axios({
                method:"GET",
                url:"https://backendsk.skinrange.com/api/apifaq",
            })
            if(faqresponse.data.status === "success"){
                setFaq(...[faqresponse.data.faqdata])
            }
            else{
                toast.error("Unable to fetched FAQ data")
            }
        } 
        catch (error) {
            toast.error("Check Connection")
        }
    }
    return (
        <>
            <Helmet>
                <title>Skinrange::FAQ</title>
            </Helmet>
            <Topbar />
            <Navbar />
            <section className="inner-section single-banner" style={{backgroundImage: `url("assets/offer/offer-middle-banner.jpg")`}}>
                <div className="container">
                    <h2>FAQ</h2>
                </div>
            </section>
            <div className="container mt-4">
                <div className="row">
                    <section className="inner-section faq-part">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-10 mx-auto">
                                    <div className="faq-parent">
                                        {faq?.map((faq)=>(
                                            <div className="faq-child" key={faq}>
                                                <div className="faq-que">
                                                    <button>{faq.faq_question}</button>
                                                </div>
                                                <div className="faq-ans">
                                                    <p>{faq.faq_ans}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Faq

