import React, { useState, useEffect } from 'react'
import Helmet from 'react-helmet';
import Navbar from '../../components/navbar/Navbar';
import Topbar from '../../components/topbar/Topbar';
import Footer from '../footer/Footer';
import "./termcondition.css";
import axios from 'axios';
import { toast } from 'react-toastify';

const TermCondition = () => {
    const [tcondition, setTcondition] = useState([])
    useEffect(() => {
        getTermCondition()
    }, []);
    const getTermCondition = async () => {
        try {
            const responsetermc = await axios({
                method: "GET",
                url: "https://backendsk.skinrange.com/api/apitermcondition",
                header:{"Content-Type":"multipart/form-data"}
            })
            if(responsetermc.data.status === "success"){
                setTcondition(...[responsetermc.data.termdata])
            }
            else{
                toast.error("Unable to fetched term and condition")
            }
        }
        catch (error) { 
            toast.error("Check Connection")
        }
    }
    return (
        <>
            <Helmet>
                <title>Skinrange::Term and Condition</title>
            </Helmet>
            <Topbar />
            <Navbar />
            <section className="inner-section single-banner" style={{ backgroundImage: `url("assets/offer/offer-middle-banner.jpg")` }}>
                <div className="container">
                    <h2>Term & Condition</h2>
                </div>
            </section>
            <section className="inner-section term-part">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-2 mb-4">
                            <div className="mt-4" data-bs-spy="scroll" data-bs-target="#scrollspy" data-bs-offset="0" tabIndex="0">
                                <div className="scrollspy-content" id="item-1">
                                    <p><div dangerouslySetInnerHTML={{__html: tcondition.policy}} /></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default TermCondition