import React,{useState, useEffect} from 'react'
import {Helmet} from "react-helmet";
import Navbar from '../../components/navbar/Navbar';
import Topbar from '../../components/topbar/Topbar';
import Footer from '../footer/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStarHalfAlt } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useCart } from 'react-use-cart';

const Bestseller = () => {
    const {addItem} = useCart()
    const addToCart = (product) => {
        addItem(product)
        toast.success("Product has been added in your cart!");
    }
    const [bestproduct, setBestProduct] = useState([])
    const baseUrl = "https://backendsk.skinrange.com/assets/product/";
    useEffect(() => {
        getProduct()
    }, []);
    const getProduct = async () => {
        try {
            const productresponse = await axios({
                method:"GET",
                url:"https://backendsk.skinrange.com/api/apibestsellerproduct",
                header:{"Content-Type":"multipart/form-data"}
            })
            if(productresponse.data.status === "success"){
                setBestProduct(...[productresponse.data.productdata])
            }
            else{
                toast.error("Unable to load product data")
            }
        } 
        catch (error) {
            toast.error("Check Connection")
        }
    }
    return (
        <div>
            <Helmet>
                <title>Skinrange::Best Seller</title>
            </Helmet>
            <Topbar />
            <Navbar />
            <section className="inner-section single-banner" style={{backgroundImage: `url("assets/offer/offer-middle-banner.jpg")`}}>
                <div className="container">
                    <h2>Best Seller</h2>
                </div>
            </section>
            <section className="inner-section shop-part mt-4">
                <div className="container">
                    <div className="row content-reverse">
                        <div className="col-lg-12">
                            <div className="row row-cols-1 row-cols-md-3 row-cols-lg-4 row-cols-xl-5">
                                
                                {bestproduct?.map((product)=>(
                                    <div className="col">
                                        <div className="product-card">
                                            <div className="product-media">
                                                <Link className="product-image" to="/product">
                                                    <img src={baseUrl + product.image} alt={product.image} />
                                                </Link>
                                            </div>
                                            <div className="product-content">
                                                <h6 className="product-name">
                                                    <Link to="/product">{product.name}</Link>
                                                </h6>
                                                <div className="product-rating">
                                                    <span className="product-ratings">4.0 <FontAwesomeIcon className="active" icon={faStarHalfAlt} /></span>
                                                </div>
                                                <h6 className="product-price">
                                                    <del>₹ {product.product_cut_price}</del>
                                                    <span>₹ {product.price}</span>
                                                </h6>
                                                <div className="add-button">
                                                    <button onClick={() => addToCart(product)} className="addtocart-button addToCart" title="Add to Cart"><i className="fas fa-shopping-basket"></i><span>Add To Cart</span></button> 
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                ))}                    
                                                                          
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Bestseller