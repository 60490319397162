import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import Navbar from '../../components/navbar/Navbar'
import Topbar from '../../components/topbar/Topbar'
import Footer from '../footer/Footer'
import "./contact.css";

const Contact = () => {
    return (
        <div className="contactus">
            <Helmet>
                <title>Skinrange::Contact us</title>
            </Helmet>
            <Topbar />
            <Navbar />
            <section className="inner-section single-banner" style={{backgroundImage: `url("assets/offer/offer-middle-banner.jpg")`}}>
                <div className="container">
                    <h2>Contact us</h2>
                </div>
            </section>
            <section className="inner-section contact-part mt-4">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="contact-map">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14008.420052751884!2d77.2225016!3d28.6266146!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf3135429c5ae1b73!2sSAT%20KARTAR%20GROUP!5e0!3m2!1sen!2sin!4v1649660577098!5m2!1sen!2sin" width="600" height="450" style={{border:"0"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <form className="contact-form" method="post" noValidate="novalidate">
                                <h4>Contact Us</h4>
                                <div className="form-group">
                                    <div className="form-input-group">
                                        <input className="form-control" name="name" id="name" type="text" placeholder="Your Name" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="form-input-group">
                                        <input className="form-control" userphone="true" type="tel" id="mobile" name="mobile" placeholder="Your Email" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="form-input-group">
                                        <input className="form-control" userphone="true" type="tel" id="mobile" name="mobile" placeholder="Your Mobile" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="form-input-group">
                                        <textarea style={{height:"148px"}} rows="3" className="form-control" type="text" name="mobile" placeholder="Message"></textarea>
                                    </div>
                                </div>
                                <button type="submit" className="form-btn-group"><i className="fas fa-envelope"></i>
                                    <span> send message</span>
                                </button>
                            </form>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-4">
                            <div className="contact-card"><i className="fa fa-map-marker"></i>
                                <h4>head office</h4>
                                <p>6th Floor, Unit Number 603, Mercantile House, K.G. Marg, New Delhi, Delhi, 110001</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="contact-card active"><i className="fa fa-phone"></i>
                                <h4>phone number</h4>
                                <p>
                                    <Link to="tel:9876543210">+91-9876543210</Link>
                                    <Link to="tel:9876543210">+91-9876543210</Link>
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="contact-card"><i className="fa fa-envelope"></i>
                                <h4>Support mail</h4>
                                <p>
                                    <Link to="#">support@skinrange.com</Link>
                                    <Link to="#">info@skinrange.com</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Contact