import React, { useState, useEffect } from 'react'
import Helmet from 'react-helmet';
import "./delivery.css";
import Navbar from '../../components/navbar/Navbar';
import Topbar from '../../components/topbar/Topbar';
import Footer from '../footer/Footer';
import axios from 'axios';
import { toast } from 'react-toastify';

const DeliveryPolicy = () => {
    const [delivery, setDelivery] = useState([])
    useEffect(() => {
        getDelivery()
    }, []);
    const getDelivery = async () =>{
        try {
            const responsedelivery = await axios({
                method:"GET",
                url:"/apishippingpolicy",
                header:{"Content-Type":"multipart/form-data"}
            })
            if(responsedelivery.data.status === "success"){
                setDelivery(...[responsedelivery.data.shippingdata])
            }
            else{
                toast.error("Unable to load delivery policy")
            }
        } 
        catch (error) {
            toast.error("Check Connection")
        }
    }
    return (
        <>
            <Helmet>
                <title>Skinrange::Shipping and Delivery Policy</title>
            </Helmet>
            <Topbar />
            <Navbar />
            <section className="inner-section single-banner" style={{backgroundImage: `url("assets/offer/offer-middle-banner.jpg")`}}>
                <div className="container">
                    <h2>Shipping & Delivery Policy</h2>
                </div>
            </section>
            <section className="inner-section term-part">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-2 mb-4">
                            <div className="mt-4" data-bs-spy="scroll" data-bs-target="#scrollspy" data-bs-offset="0" tabIndex="0">
                                <p><div dangerouslySetInnerHTML={{__html: delivery.policy}} /></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default DeliveryPolicy