import "./bestseller.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useCart } from "react-use-cart";

const BestSeller = () => {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 0
            }
          },
          {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                initialSlide: 0
            }
          }
        ]
    };

    const {addItem} = useCart()
    const addToCart = (product) =>{
        addItem(product)
        toast.success("Product has been added in your cart!");
    }
    const [bestsellere, setBestSeller] = useState([])
    const baseUrl = "https://backendsk.skinrange.com/assets/product/";
    useEffect(()=>{
        getBestSeller()
    },[])

    const getBestSeller = async () =>{
        try {
            const responsebest = await axios({
                method:"GET",
                url:"https://backendsk.skinrange.com/api/apibestsellerproduct",
            })
            if(responsebest.data.status === "success"){
                setBestSeller(...[responsebest.data.productdata])
            }
            else{
                toast.error("Unable to load best seller data")
            }
        } 
        catch (error) {
            toast.error("Check Connection")
        }
    }
        
    return (
        <div className="bestsellerContainer">
            <div className="container">
                <div className="row">
                    <div class="col-lg-12">
                        <div class="section-heading">
                            <h2>Best Seller</h2>
                        </div>
                    </div>
                    <Slider {...settings} classNam ="row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5">
                        {bestsellere?.map((product)=>(
                            <div className="col productbox" key={product.id}>
                                <div className="product-card">
                                    <div className="product-media">
                                        <Link className="product-image" to="/product">
                                            <img src={baseUrl + product.image} alt={product.image} />
                                        </Link>
                                    </div>
                                    <div className="product-content">
                                        <h6 className="product-name">
                                            <Link to="/product">{product.name}</Link>
                                        </h6>
                                        <h6 className="product-price">
                                            <del>₹ {product.product_cut_price}</del>
                                            <span>₹ {product.price}</span>
                                        </h6>
                                        <div className="add-button">
                                            <button onClick={() => addToCart(product)} className="addtocart-button" title="Add to Cart"><i className="fas fa-shopping-basket" /><span>Add To Cart</span></button> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    );
}

export default BestSeller
