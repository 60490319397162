import {useState, useEffect} from 'react';
import {getRemainingTimeUntilMsTimestamp} from './CountdownTimerUtils';

const defaultRemainingTime = {
    seconds: '00',
    minutes: '00',
    hours: '00',
    days: '00'
}

const CountdownTimer = ({countdownTimestampMs}) => {
    const [remainingTime, setRemainingTime] = useState(defaultRemainingTime);

    useEffect(() => {
        const intervalId = setInterval(() => {
            updateRemainingTime(countdownTimestampMs);
        }, 1000);
        return () => clearInterval(intervalId);
    },[countdownTimestampMs]);

    function updateRemainingTime(countdown) {
        setRemainingTime(getRemainingTimeUntilMsTimestamp(countdown));
    }

    return(
        <div className="deals-countdown-wrap">
            <div className="deals-countdown" data-countdown="2025/03/25 00:00:00">
                <span className="countdown-section">
                    <span className="countdown-amount hover-up">{remainingTime.days}</span>
                    <span className="countdown-period"> days </span>
                </span>
                <span className="countdown-section">
                    <span className="countdown-amount hover-up">{remainingTime.hours}</span>
                    <span className="countdown-period"> hours </span>
                </span>
                <span className="countdown-section">
                    <span className="countdown-amount hover-up">{remainingTime.minutes}</span>
                    <span className="countdown-period"> mins </span>
                </span>
                <span className="countdown-section">
                    <span className="countdown-amount hover-up">{remainingTime.seconds}</span>
                    <span className="countdown-period"> sec </span>
                </span>
            </div>
        </div>
    );
}

export default CountdownTimer;