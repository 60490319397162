import React from 'react'
import "./topselling.css";
import { Link } from 'react-router-dom'

const TopSelling = () => {
    return (
        <div className="topselling">
            <section className="section-padding mb-30">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-4 col-md-6 mb-sm-5 mb-md-0 wow animate__ tanimate__fadeInUp animated">
                            <h4 className="tsection-title style-1 mb-30 animated animated">Top Selling</h4>
                            <div className="product-list-small animated animated">
                                <article className="row align-items-center hover-up">
                                    <figure className="col-md-4 mb-0">
                                        <Link to="/best-seller">
                                            <img src="assets/thumbnail/thumbnail-1.jpg" alt="" />
                                        </Link>
                                    </figure>
                                    <div className="col-md-8 mb-0">
                                        <h6>
                                            <Link to="/best-seller">Nestle Original Coffee-Mate Coffee Creamer</Link>
                                        </h6>
                                        <div className="tproduct-price">
                                            <span>$32.85</span>
                                            <span className="old-price">$33.8</span>
                                        </div>
                                    </div>
                                </article>
                                <article className="row align-items-center hover-up">
                                    <figure className="col-md-4 mb-0">
                                        <a href="shop-product-right.html">
                                            <img src="assets/thumbnail/thumbnail-2.jpg" alt="" />
                                        </a>
                                    </figure>
                                    <div className="col-md-8 mb-0">
                                        <h6>
                                            <a href="shop-product-right.html">Nestle Original Coffee-Mate Coffee Creamer</a>
                                        </h6>
                                        <div className="tproduct-price">
                                            <span>$32.85</span>
                                            <span className="old-price">$33.8</span>
                                        </div>
                                    </div>
                                </article>
                                <article className="row align-items-center hover-up">
                                    <figure className="col-md-4 mb-0">
                                        <a href="shop-product-right.html">
                                            <img src="assets/thumbnail/thumbnail-3.jpg" alt="" />
                                        </a>
                                    </figure>
                                    <div className="col-md-8 mb-0">
                                        <h6>
                                            <a href="shop-product-right.html">Nestle Original Coffee-Mate Coffee Creamer</a>
                                        </h6>
                                        <div className="tproduct-price">
                                            <span>$32.85</span>
                                            <span className="old-price">$33.8</span>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 mb-md-0 wow animate__ tanimate__fadeInUp animated">
                            <h4 className="tsection-title style-1 mb-30 animated animated">Trending Products</h4>
                            <div className="product-list-small animated animated">
                                <article className="row align-items-center hover-up">
                                    <figure className="col-md-4 mb-0">
                                        <a href="shop-product-right.html">
                                            <img src="assets/thumbnail/thumbnail-4.jpg" alt="" />
                                        </a>
                                    </figure>
                                    <div className="col-md-8 mb-0">
                                        <h6>
                                            <a href="shop-product-right.html">Organic Cage-Free Grade A Large Brown Eggs</a>
                                        </h6>
                                        <div className="tproduct-price">
                                            <span>$32.85</span>
                                            <span className="old-price">$33.8</span>
                                        </div>
                                    </div>
                                </article>
                                <article className="row align-items-center hover-up">
                                    <figure className="col-md-4 mb-0">
                                        <a href="shop-product-right.html">
                                            <img src="assets/thumbnail/thumbnail-5.jpg" alt="" />
                                        </a>
                                    </figure>
                                    <div className="col-md-8 mb-0">
                                        <h6>
                                            <a href="shop-product-right.html">Seeds of Change Organic Quinoa, Brown, &amp; Red Rice</a>
                                        </h6>
                                        <div className="tproduct-price">
                                            <span>$32.85</span>
                                            <span className="old-price">$33.8</span>
                                        </div>
                                    </div>
                                </article>
                                <article className="row align-items-center hover-up">
                                    <figure className="col-md-4 mb-0">
                                        <a href="shop-product-right.html">
                                            <img src="assets/thumbnail/thumbnail-6.jpg" alt="" />
                                        </a>
                                    </figure>
                                    <div className="col-md-8 mb-0">
                                        <h6>
                                            <a href="shop-product-right.html">Naturally Flavored Cinnamon Vanilla Light Roast Coffee</a>
                                        </h6>
                                        <div className="tproduct-price">
                                            <span>$32.85</span>
                                            <span className="old-price">$33.8</span>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 mb-sm-5 mb-md-0 d-none d-lg-block wow animate__ tanimate__fadeInUp animated">
                            <h4 className="tsection-title style-1 mb-30 animated animated">Recently added</h4>
                            <div className="product-list-small animated animated">
                                <article className="row align-items-center hover-up">
                                    <figure className="col-md-4 mb-0">
                                        <a href="shop-product-right.html">
                                            <img src="assets/thumbnail/thumbnail-7.jpg" alt="" />
                                        </a>
                                    </figure>
                                    <div className="col-md-8 mb-0">
                                        <h6>
                                            <a href="shop-product-right.html">Pepperidge Farm Farmhouse Hearty White Bread</a>
                                        </h6>
                                        <div className="tproduct-price">
                                            <span>$32.85</span>
                                            <span className="old-price">$33.8</span>
                                        </div>
                                    </div>
                                </article>
                                <article className="row align-items-center hover-up">
                                    <figure className="col-md-4 mb-0">
                                        <a href="shop-product-right.html">
                                            <img src="assets/thumbnail/thumbnail-8.jpg" alt="" /> 
                                        </a>
                                    </figure>
                                    <div className="col-md-8 mb-0">
                                        <h6>
                                            <a href="shop-product-right.html">Organic Frozen Triple Berry Blend</a>
                                        </h6>
                                        <div className="tproduct-price">
                                            <span>$32.85</span>
                                            <span className="old-price">$33.8</span>
                                        </div>
                                    </div>
                                </article>
                                <article className="row align-items-center hover-up">
                                    <figure className="col-md-4 mb-0">
                                        <a href="shop-product-right.html">
                                            <img src="assets/thumbnail/thumbnail-9.jpg" alt="" />
                                        </a>
                                    </figure>
                                    <div className="col-md-8 mb-0">
                                        <h6>
                                            <a href="shop-product-right.html">Oroweat Country Buttermilk Bread</a>
                                        </h6>
                                        <div className="tproduct-price">
                                            <span>$32.85</span>
                                            <span className="old-price">$33.8</span>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 mb-sm-5 mb-md-0 d-none d-xl-block wow animate__ tanimate__fadeInUp animated">
                            <h4 className="tsection-title style-1 mb-30 animated animated">Top Rated</h4>
                            <div className="product-list-small animated animated">
                                <article className="row align-items-center hover-up">
                                    <figure className="col-md-4 mb-0">
                                        <a href="shop-product-right.html">
                                            <img src="assets/thumbnail/thumbnail-10.jpg" alt="" />
                                        </a>
                                    </figure>
                                    <div className="col-md-8 mb-0">
                                        <h6>
                                            <a href="shop-product-right.html">Foster Farms Takeout Crispy classNameic Buffalo Wings</a>
                                        </h6>
                                        <div className="tproduct-price">
                                            <span>$32.85</span>
                                            <span className="old-price">$33.8</span>
                                        </div>
                                    </div>
                                </article>
                                <article className="row align-items-center hover-up">
                                    <figure className="col-md-4 mb-0">
                                        <a href="shop-product-right.html">
                                            <img src="assets/thumbnail/thumbnail-11.jpg" alt="" />
                                        </a>
                                    </figure>
                                    <div className="col-md-8 mb-0">
                                        <h6>
                                            <a href="shop-product-right.html">Angie’s Boomchickapop Sweet &amp; Salty Kettle Corn</a>
                                        </h6>
                                        <div className="tproduct-price">
                                            <span>$32.85</span>
                                            <span className="old-price">$33.8</span>
                                        </div>
                                    </div>
                                </article>
                                <article className="row align-items-center hover-up">
                                    <figure className="col-md-4 mb-0">
                                        <a href="shop-product-right.html">
                                            <img src="assets/thumbnail/thumbnail-12.jpg" alt="" />
                                        </a>
                                    </figure>
                                    <div className="col-md-8 mb-0">
                                        <h6>
                                            <a href="shop-product-right.html">All Natural Italian-Style Chicken Meatballs</a>
                                        </h6>
                                        <div className="tproduct-price">
                                            <span>$32.85</span>
                                            <span className="old-price">$33.8</span>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default TopSelling