import React, { useState } from 'react';
import { faContactCard } from '@fortawesome/free-regular-svg-icons'
import { faBars, faClose, faHome, faSearch, faSignIn,faSignOut, faUser, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./topbar.css";
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';

const Topbar = () => {
    const userdata = JSON.parse(localStorage.getItem('userdata'));
    const navigate = useNavigate()

    window.addEventListener("scroll", function () {
        const topbar = document.querySelector(".topbar")
        topbar.classList.toggle("active", window.scrollY > 100)
    });
    const [Mobile, setMobile] = useState(false);

    function logout(){
        localStorage.clear();
        navigate("/login");
    }
    return (
        <div className="topbar">
            <div className="container topbarContainer">
                <span className="welcome">Welcome to Skinrange</span>
                <div className="mobileLogo">
                    <Link to="/">
                        <img className="mobilelogo" src={window.location.origin + "/assets/images/skinrangelogo.png"} alt="logo" />
                    </Link>
                </div>
                <button className="tongle" onClick={() => setMobile(!Mobile)}>
                    {
                        Mobile ? <FontAwesomeIcon icon={faClose} className="close closebtn" /> : <FontAwesomeIcon icon={faBars} className="open closebtn" />
                    }
                </button>
                <div className="searchBar">
                    <input type="text" className="inputSearch" placeholder="Search Item" />
                    <button className="searchBtn">
                        <FontAwesomeIcon icon={faSearch} className="headerIcon" />
                    </button>
                </div>
                <div className="topbarItem">
                    <Link to="/contact-us" className="topbarButton"><FontAwesomeIcon icon={faContactCard} /> Contact Us</Link>
                    {
                    localStorage.getItem('userdata') ?
                    <>
                        <Link to="/user-dashboard" className="topbarButton"><FontAwesomeIcon icon={faUser} /> My Profile</Link>
                        <a href="javascript:void(0)" onClick={logout} className="topbarButton"><FontAwesomeIcon icon={faSignOut} /> LogOut</a>
                    </>
                    : 
                    <>
                        <Link to="/login" className="topbarButton"><FontAwesomeIcon icon={faSignIn} /> Login</Link>
                    </>
                    }

                </div>
                <div className="mobilenavlink">
                    <ul className={Mobile ? "mobilenavbarmegamenu" : "mobilenavbarmegamenufalse"} onClick={() => setMobile(false)}>
                        <li><Link to="/"><img className="mobileLogos" src={window.location.origin + "/assets/images/skinrangelogo.png"} alt="logo" /></Link></li>
                        <li><Link to="/" className="mobilemegaMenu"><FontAwesomeIcon className="homeIcon" icon={faHome} />Home</Link></li>
                        <li><Link to="/product" className="mobilemegaMenu">Product</Link></li>
                        <li><Link to="/best-seller" className="mobilemegaMenu">Best Seller</Link></li>
                        <li><Link to="/popular" className="mobilemegaMenu">Popular</Link></li>
                        <li><Link to="/contact-us" className="mobilemegaMenu"> Contact Us</Link></li>
                        <hr />
                        <li><Link to="/register" className="mobilemegaMenu mobileregbtn"><FontAwesomeIcon icon={faUserPlus} /> Register</Link></li>
                        <li><Link to="/login" className="mobilemegaMenu mobileloginbtn"><FontAwesomeIcon icon={faSignIn} /> Login</Link></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Topbar
