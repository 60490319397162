import React from 'react'
import Fsection1 from '../fsection1/Fsection1';
import "./fsection2.css";

const Fsection2 = () => {
    return (
        <>
            <Fsection1 />
            <section id="" className="section_2  section-color mt-4">
				<div className="container page-builder-ltr">
				    <div className="row row_m1ch  row-style  row-color ">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col_itqc  col-style">
							<div className="clearfix bonus-menus bonus-menu-4">
                                <ul className="footer-section2">
                                    <li className="item secure col-md-3">
                                        <div className="icon">
                                            <img src="assets/service/icon-secure-3.png" alt="" />
                                        </div>
                                        <div className="text">
                                            <h5><a href="#">100% Secure Payments</a></h5>
                                            <p>All major credit &amp; debit</p>
                                            <p> cards accepted</p>
                                        </div>
                                    </li>
                                    <li className="item help col-md-3">
                                        <div className="icon">
                                            <img src="assets/service/icon-help-3.png" alt="" />
                                        </div>
                                        <div className="text">
                                            <h5><a href="#">Help Center</a></h5>
                                            <p>Got a question? Look no further. </p>
                                            <p> Browse our FAQs or submit your here.</p>
                                        </div>
                                    </li>
                                    <li className="item trustpay col-md-3">
                                        <div className="icon">
                                            <img src="assets/service/icon-trust-3.png" alt="" />
                                        </div>
                                        <div className="text">
                                            <h5><a href="#">TrustPay</a></h5>
                                            <p>100% Payment Protection. Easy</p>
                                            <p> Return Policy </p>
                                        </div>
                                    </li>
                                    <li className="item delivery col-md-3">
                                        <div className="icon">
                                            <img src="assets/service/icon-delivery-3.png" alt="" />
                                        </div>
                                        <div className="text">
                                            <h5><a href="#">Worldwide Delivery</a></h5>
                                            <p>With sites in 5 languages, we ship to </p>
                                            <p>over 200 countries &amp; regions.</p>
                                        </div>
                                    </li>
                                    <li className="item value col-md-3">
                                        <div className="icon">
                                            <img src="assets/service/icon-value-3.png" alt="" />
                                        </div>
                                        <div className="text">
                                            <h5><a href="#">Great Value</a></h5>
                                            <p>We offer competitive prices on our 100</p>
                                            <p>million plus product range.</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>										
						</div>					
				    </div>
			    </div>
			</section>
        </>
    )
}

export default Fsection2