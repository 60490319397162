import React, { useState, useEffect } from 'react'
import "./returnpolicy.css";
import Navbar from '../../components/navbar/Navbar';
import Topbar from '../../components/topbar/Topbar';
import Footer from '../footer/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import Helmet from 'react-helmet';
import axios from 'axios';
import { toast } from 'react-toastify';

const ReturnPolicy = () => {
    const [rpolicy, setRpolicy] = useState([])
    useEffect(() => {
        getRpolicy()
    }, [])

    const getRpolicy = async () =>{
        try {
            const responserpolicy = await axios({
                method:"GET",
                url:"https://backendsk.skinrange.com/api/apicancelationpolicy",
            })
            if(responserpolicy.data.status === "success"){
                setRpolicy(...[responserpolicy.data.cancledata])
            }
            else{
                toast.error("Unable to load return policy data!!")
            }
        } 
        catch (error) {
            toast.error("Check Connection!!")
        }
    }
    
    return (
        <>
            <Helmet>
                <title>Skinrange::Return and Canclation Policy</title>
            </Helmet>
            <Topbar />
            <Navbar />
            <section className="inner-section single-banner" style={{backgroundImage: `url("assets/offer/offer-middle-banner.jpg")`}}>
                <div className="container">
                    <h2>Return & Canclation Policy</h2>
                </div>
            </section>
            <section className="inner-section term-part">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-2 mb-4">
                            <div className="mt-4" data-bs-spy="scroll" data-bs-target="#scrollspy" data-bs-offset="0" tabIndex="0">
                                <p><div dangerouslySetInnerHTML={{__html: rpolicy.policy}} /></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default ReturnPolicy