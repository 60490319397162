import "./homecategory.css";
import React,{useState, useEffect} from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { toast } from "react-toastify";


const HomeCategory = () => {
    var settings = {
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        initialSlide: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    initialSlide: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const [category, setCategory] = useState([])
    const baseUrl = "https://backendsk.skinrange.com/assets/category/";
    useEffect(() => {
        getCategory();
    }, []);
    const getCategory = async () => {
        try {
            const categoryresponse = await axios({
                method: "GET",
                url: "https://backendsk.skinrange.com/api/apicategory",
            })
            if(categoryresponse.status === 200){
                setCategory(...[categoryresponse.data.categorydata])
            }
            else{
                toast.error("Unable load Category!!")
            }
        } 
        catch (error) {
            toast.error("Check Connection")
        }
    }
    return (
        <div className="homeCategory section suggest-part">
            <div className="container">
                <div className="row">
                    <Slider {...settings} className="homeCategoryContainer">
                        {category?.map((category)=>(
                            <div className="categoryItem col-md-4">
                                <Link className="suggest-card" to="/" key={category}>
                                    <img className="categoryImg" src={baseUrl + category.cat_img} alt={category.cat_img} />
                                    <h5>{category.cat_name} <span>{category.pcount} items</span></h5>
                                </Link>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export default HomeCategory
