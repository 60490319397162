import React, { useState,useEffect } from 'react'
import Helmet from 'react-helmet'
import axios from 'axios';
import { useNavigate} from 'react-router-dom';
import Navbar from '../../components/navbar/Navbar'
import Topbar from '../../components/topbar/Topbar'
import Footer from '../footer/Footer'
import "./login.css";
import {  toast } from 'react-toastify';

const Login = () => {
    const navigate = useNavigate()

    useEffect(() =>{
        const userdata = localStorage.getItem('userdata');
        if(userdata){
            navigate("/user-dashboard")
        }
    });
    const [error, setError] = useState({
        status: false,
        msg: "",
        type: ""
    });
    const [mobile, setMobile] = useState("")


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (mobile){
            try {
                const sendotp = await axios({
                    method: "post",
                    url: "https://backendsk.skinrange.com/api/apilogin-register",
                    data: {mobile:mobile},
                    headers: { "Content-Type": "multipart/form-data" },
                })
                if(sendotp.data.status==="success"){
                    localStorage.setItem("usermobile",mobile)
                    toast.success("Mobile OTP has been send successfully!");
                    navigate('/otp')
                }
            } 
            catch (error) {
                toast.error("Unable to send mobile OTP!");
                setError({ status: true, msg: "Unable to send mobile OTP", type: "red" })
            }
        }
        else {
            setError({ status: true, msg: "All fields are required", type: "red" })
        }
    }
    return (
        <div>
            <Helmet>
                <title>Skinrange::Login</title>
            </Helmet>
            <Topbar />
            <Navbar />
            <section className="inner-section single-banner" style={{ backgroundImage: `url("assets/offer/offer-middle-banner.jpg")` }}>
                <div className="container">
                    <h2>SignUp or SignIn</h2>
                </div>
            </section>
            <section className="user-form-part">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-sm-5 col-md-6 col-lg-6 col-xl-5">
                            <div className="user-form-card">
                                <div className="user-form-title">
                                    <h2>Signup or Signin</h2>
                                </div>
                                {error.status ? <span style={{ color: error.type, textAlign: 'center', fontWeight: 'bold' }}>{error.msg}</span> : ""}
                                <div className="user-form-group">
                                    <form className="user-form" method="post" onSubmit={handleSubmit} noValidate="novalidate">
                                        <div className="form-group">
                                            <label htmlFor="mobile">Mobile:</label>
                                            <input type="tel" name="mobile" id="mobile" className="form-control" placeholder="Enter your Mobile Number" onChange={e => setMobile(e.target.value)} />
                                        </div>
                                        <div className="form-button">
                                            <button className="register">Login</button>
                                        </div>
                                        <div className="sociallogin" style={{ marginTop: '32px' }}>
                                            <h1><span>Or Continue with</span></h1>
                                            <div className="form-button socialloginbtn">
                                                <button className="google"><img src="assets/social/google_logo.svg" alt="" /> Login in with google</button>
                                                <button className="facebook"><img src="assets/social/facebook_logo.svg" alt="" /> Login in with facebook</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Login