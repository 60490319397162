import React from 'react'
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import Navbar from '../../components/navbar/Navbar';
import Topbar from '../../components/topbar/Topbar';
import Footer from '../footer/Footer';
import "./blog.css";

const Blog = () => {
    return (
        <>
            <div className="blog">
                <Helmet>
                    <title>Skinrange::Blog</title>
                </Helmet>
                <Topbar />
                <Navbar />
                <section className="inner-section single-banner" style={{backgroundImage: `url("assets/offer/offer-middle-banner.jpg")`}}>
                    <div className="container">
                        <h2>Blog</h2>
                    </div>
                </section>
                <section className="inner-section blog-grid mt-4">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-9">
                                <div className="row">
                                    <div className="col-md-6 col-lg-4">
                                        <div className="blog-card">
                                            <div className="blog-media">
                                                <Link className="blog-img" to="/blog/test">
                                                    <img src="assets/blog/01.jpg" alt="blog" />
                                                </Link>
                                            </div>
                                            <div className="blog-content">
                                                <ul className="blog-meta">
                                                    <li><i className="fas fa-user"></i><span>admin</span></li>
                                                    <li><i className="fas fa-calendar-alt"></i><span>february 02, 2021</span></li>
                                                </ul>
                                                <h4 className="blog-title"><Link to="/blog/test">Voluptate blanditiis provident Lorem ipsum dolor sit amet</Link></h4>
                                                <p className="blog-desc">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Alias autem recusandae deleniti nam dignissimos sequi ... </p><Link className="blog-btn" to="/blog/test"><span>read more</span><i className="fab fa-arrow-right"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <div className="blog-card">
                                            <div className="blog-media">
                                                <a className="blog-img" href="#">
                                                    <img src="assets/blog/02.jpg" alt="blog" />
                                                </a>
                                            </div>
                                            <div className="blog-content">
                                                <ul className="blog-meta">
                                                    <li><i className="fas fa-user"></i><span>admin</span></li>
                                                    <li><i className="fas fa-calendar-alt"></i><span>february 02, 2021</span></li>
                                                </ul>
                                                <h4 className="blog-title"><a href="blog-details.html">Voluptate blanditiis provident Lorem ipsum dolor sit amet</a></h4>
                                                <p className="blog-desc">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Alias autem recusandae deleniti nam dignissimos sequi ... </p><a className="blog-btn" href="#"><span>read more</span><i className="fab fa-arrow-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <div className="blog-card">
                                            <div className="blog-media">
                                                <a className="blog-img" href="#">
                                                    <img src="assets/blog/03.jpg" alt="blog" />
                                                </a>
                                            </div>
                                            <div className="blog-content">
                                                <ul className="blog-meta">
                                                    <li><i className="fas fa-user"></i><span>admin</span></li>
                                                    <li><i className="fas fa-calendar-alt"></i><span>february 02, 2021</span></li>
                                                </ul>
                                                <h4 className="blog-title"><a href="blog-details.html">Voluptate blanditiis provident Lorem ipsum dolor sit amet</a></h4>
                                                <p className="blog-desc">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Alias autem recusandae deleniti nam dignissimos sequi ... </p><a className="blog-btn" href="#"><span>read more</span><i className="fab fa-arrow-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <div className="blog-card">
                                            <div className="blog-media">
                                                <a className="blog-img" href="#">
                                                    <img src="assets/blog/04.jpg" alt="blog" />
                                                </a>
                                            </div>
                                            <div className="blog-content">
                                                <ul className="blog-meta">
                                                    <li><i className="fas fa-user"></i><span>admin</span></li>
                                                    <li><i className="fas fa-calendar-alt"></i><span>february 02, 2021</span></li>
                                                </ul>
                                                <h4 className="blog-title"><a href="blog-details.html">Voluptate blanditiis provident Lorem ipsum dolor sit amet</a></h4>
                                                <p className="blog-desc">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Alias autem recusandae deleniti nam dignissimos sequi ... </p><a className="blog-btn" href="#"><span>read more</span><i className="fab fa-arrow-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <div className="blog-card">
                                            <div className="blog-media">
                                                <a className="blog-img" href="#">
                                                    <img src="assets/blog/05.jpg" alt="blog" />
                                                </a>
                                            </div>
                                            <div className="blog-content">
                                                <ul className="blog-meta">
                                                    <li><i className="fas fa-user"></i><span>admin</span></li>
                                                    <li><i className="fas fa-calendar-alt"></i><span>february 02, 2021</span></li>
                                                </ul>
                                                <h4 className="blog-title"><a href="blog-details.html">Voluptate blanditiis provident Lorem ipsum dolor sit amet</a></h4>
                                                <p className="blog-desc">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Alias autem recusandae deleniti nam dignissimos sequi ... </p><a className="blog-btn" href="#"><span>read more</span><i className="fab fa-arrow-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <div className="blog-card">
                                            <div className="blog-media">
                                                <a className="blog-img" href="#">
                                                    <img src="assets/blog/06.jpg" alt="blog" />
                                                </a>
                                            </div>
                                            <div className="blog-content">
                                                <ul className="blog-meta">
                                                    <li><i className="fas fa-user"></i><span>admin</span></li>
                                                    <li><i className="fas fa-calendar-alt"></i><span>february 02, 2021</span></li>
                                                </ul>
                                                <h4 className="blog-title"><a href="blog-details.html">Voluptate blanditiis provident Lorem ipsum dolor sit amet</a></h4>
                                                <p className="blog-desc">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Alias autem recusandae deleniti nam dignissimos sequi ... </p><a className="blog-btn" href="#"><span>read more</span><i className="fab fa-arrow-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <div className="blog-card">
                                            <div className="blog-media">
                                                <a className="blog-img" href="#">
                                                    <img src="assets/blog/07.jpg" alt="blog" />
                                                </a>
                                            </div>
                                            <div className="blog-content">
                                                <ul className="blog-meta">
                                                    <li><i className="fas fa-user"></i><span>admin</span></li>
                                                    <li><i className="fas fa-calendar-alt"></i><span>february 02, 2021</span></li>
                                                </ul>
                                                <h4 className="blog-title"><a href="blog-details.html">Voluptate blanditiis provident Lorem ipsum dolor sit amet</a></h4>
                                                <p className="blog-desc">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Alias autem recusandae deleniti nam dignissimos sequi ... </p><a className="blog-btn" href="#"><span>read more</span><i className="fab fa-arrow-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <div className="blog-card">
                                            <div className="blog-media">
                                                <a className="blog-img" href="#">
                                                    <img src="assets/blog/08.jpg" alt="blog" />
                                                </a>
                                            </div>
                                            <div className="blog-content">
                                                <ul className="blog-meta">
                                                    <li><i className="fas fa-user"></i><span>admin</span></li>
                                                    <li><i className="fas fa-calendar-alt"></i><span>february 02, 2021</span></li>
                                                </ul>
                                                <h4 className="blog-title"><a href="blog-details.html">Voluptate blanditiis provident Lorem ipsum dolor sit amet</a></h4>
                                                <p className="blog-desc">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Alias autem recusandae deleniti nam dignissimos sequi ... </p><a className="blog-btn" href="#"><span>read more</span><i className="fab fa-arrow-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7 col-lg-3">
                                <div className="blog-widget">
                                    <h3 className="blog-widget-title">popular feeds</h3>
                                    <ul className="blog-widget-feed">
                                        <li><a className="blog-widget-media" href="#">
                                            <img src="assets/blog/01.jpg" alt="blog-widget" /></a>
                                            <h6 className="blog-widget-text"><a href="#">Lorem ipsum dolor sit amet consectetur</a><span>february 02, 2021</span></h6>
                                        </li>
                                        <li><a className="blog-widget-media" href="#">
                                            <img src="assets/blog/02.jpg" alt="blog-widget" /></a>
                                            <h6 className="blog-widget-text"><a href="#">Lorem ipsum dolor sit amet consectetur</a><span>february 02, 2021</span></h6>
                                        </li>
                                        <li><a className="blog-widget-media" href="#">
                                            <img src="assets/blog/03.jpg" alt="blog-widget" /></a>
                                            <h6 className="blog-widget-text"><a href="#">Lorem ipsum dolor sit amet consectetur</a><span>february 02, 2021</span></h6>
                                        </li>
                                        <li><a className="blog-widget-media" href="#">
                                            <img src="assets/blog/04.jpg" alt="blog-widget" /></a>
                                            <h6 className="blog-widget-text"><a href="#">Lorem ipsum dolor sit amet consectetur</a><span>february 02, 2021</span></h6>
                                        </li>
                                        <li><a className="blog-widget-media" href="#">
                                            <img src="assets/blog/05.jpg" alt="blog-widget" /></a>
                                            <h6 className="blog-widget-text"><a href="#">Lorem ipsum dolor sit amet consectetur</a><span>february 02, 2021</span></h6>
                                        </li>
                                    </ul>
                                </div>
                                <div className="blog-widget">
                                    <h3 className="blog-widget-title">top categories</h3>
                                    <ul className="blog-widget-category">
                                        <li><a href="#">farming <span>22</span></a></li>
                                        <li><a href="#">agriculture <span>14</span></a></li>
                                        <li><a href="#">organic food <span>35</span></a></li>
                                        <li><a href="#">vegetables <span>67</span></a></li>
                                        <li><a href="#">healthy life <span>89</span></a></li>
                                    </ul>
                                </div>
                                <div className="blog-widget">
                                    <h3 className="blog-widget-title">popular tags</h3>
                                    <ul className="blog-widget-tag">
                                        <li><a href="#">farming</a></li>
                                        <li><a href="#">organic</a></li>
                                        <li><a href="#">food</a></li>
                                        <li><a href="#">healthy</a></li>
                                        <li><a href="#">agriculture</a></li>
                                        <li><a href="#">vegetables</a></li>
                                        <li><a href="#">grocery</a></li>
                                        <li><a href="#">vegan</a></li>
                                        <li><a href="#">life</a></li>
                                        <li><a href="#">enjoy</a></li>
                                    </ul>
                                </div>
                                <div className="blog-widget">
                                    <h3 className="blog-widget-title">follow us</h3>
                                    <ul className="blog-widget-social">
                                        <li><a href="#" className="fab fa-facebook-f"></a></li>
                                        <li><a href="#" className="fab fa-twitter"></a></li>
                                        <li><a href="#" className="fab fa-pinterest"></a></li>
                                        <li><a href="#" className="fab fa-linkedin"></a></li>
                                        <li><a href="#" className="fab fa-instagram"></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </>
    )
}

export default Blog