import React from 'react';
import { faHome, faShoppingBasket } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./navbar.css";
import { Link } from 'react-router-dom'
import { useCart } from "react-use-cart";

const Navbar = () => {
    const {
        totalUniqueItems,
    } = useCart();

    window.addEventListener("scroll", function(){
        const navbar = document.querySelector(".navbar")
        navbar.classList.toggle("active", window.scrollY > 100)
    });
    return (
        <div className="navbar">
            <div className="navbarContainer d_flex">
                <div className="headerLogo">
                    <Link to="/">
                        <img className="logo" src={window.location.origin + '/assets/images/skinrangelogo.png'} alt="logo" />
                    </Link>
                </div>
                <div className="navlink">
                    <ul className="navbarMegamenu">
                        <li><Link to="/" className="megaMenu"><FontAwesomeIcon className="homeIcon" icon={faHome} />Home</Link></li>
                        <li><Link to="/product" className="megaMenu">Product</Link></li>
                        <li><Link to="/best-seller" className="megaMenu">Best Seller</Link></li>
                        <li><Link to="/popular" className="megaMenu">Popular</Link></li>
                    </ul>
                </div>
                <div className="headerCart">
                    <Link to="/cart">
                        <FontAwesomeIcon className="shoppingIcon" icon={faShoppingBasket} />
                        <span className="cartCounter">{totalUniqueItems}</span>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Navbar
