import React from 'react'
import "./deals.css";
import CountdownTimer from '../countdowntimer/CountdownTimer';

const Deals = () => {

    return (
        <div className="deals mb-4">
            <section className="section-padding pb-5">
                <div className="container">
                    <div className="section-title ">
                        <h3 className="">Deals Of The Day</h3>
                    </div>
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-6">
                            <div className="product-cart-wrap style-2 wow animate__ animate__fadeInUp animated" data-wow-delay="0" style={{visibility: 'visible', animationName: 'fadeUp'}}>
                                <div className="product-img-action-wrap">
                                    <div className="product-img">
                                        <a href="shop-product-right.html">
                                            <img src="assets/deals/deal-4.png" alt="" />
                                        </a>
                                    </div>
                                </div>
                                <div className="product-content-wrap">
                                    <CountdownTimer countdownTimestampMs={1659983662000}/>
                                    <div className="deals-content">
                                        <h2><a href="shop-product-right.html">Seeds of Change Organic Quinoa, Brown, &amp; Red Rice</a></h2>
                                        <div>
                                            <span className="font-small text-muted">By <a href="vendor-details-1.html">NestFood</a></span>
                                        </div>
                                        <div className="product-card-bottom">
                                            <div className="product-price">
                                                <span>₹ 32.85</span>
                                                <span className="old-price">₹ 33.8</span>
                                            </div>
                                            <div className="add-cart">
                                                <a className="addcart" href="#"><i className="fa fa-shopping-cart"></i>Add </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6">
                            <div className="product-cart-wrap style-2 wow animate__ animate__fadeInUp animated" data-wow-delay=".1s" style={{visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeInUp'}}>
                                <div className="product-img-action-wrap">
                                    <div className="product-img">
                                        <a href="shop-product-right.html">
                                            <img src="assets/deals/deal-3.png" alt="" />
                                        </a>
                                    </div>
                                </div>
                                <div className="product-content-wrap">
                                    <CountdownTimer countdownTimestampMs={1659983662000}/>
                                    <div className="deals-content">
                                        <h2><a href="shop-product-right.html">Perdue Simply Smart Organics Gluten Free</a></h2>
                                        <div>
                                            <span className="font-small text-muted">By <a href="vendor-details-1.html">Old El Paso</a></span>
                                        </div>
                                        <div className="product-card-bottom">
                                            <div className="product-price">
                                                <span>₹ 24.85</span>
                                                <span className="old-price">₹ 26.8</span>
                                            </div>
                                            <div className="add-cart">
                                                <a className="addcart" href="#"><i className="fa fa-shopping-cart"></i>Add </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6">
                            <div className="product-cart-wrap style-2 wow animate__ animate__fadeInUp animated" data-wow-delay=".2s" style={{visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeInUp'}}>
                                <div className="product-img-action-wrap">
                                    <div className="product-img">
                                        <a href="shop-product-right.html">
                                            <img src="assets/deals/deal-2.png" alt="" />
                                        </a>
                                    </div>
                                </div>
                                <div className="product-content-wrap">
                                    <CountdownTimer countdownTimestampMs={1659983662000}/>
                                    <div className="deals-content">
                                        <h2><a href="shop-product-right.html">Signature Wood-Fired Mushroom and Caramelized</a></h2>
                                        <div>
                                            <span className="font-small text-muted">By <a href="vendor-details-1.html">Progresso</a></span>
                                        </div>
                                        <div className="product-card-bottom">
                                            <div className="product-price">
                                                <span>₹ 12.85</span>
                                                <span className="old-price">₹ 13.8</span>
                                            </div>
                                            <div className="add-cart">
                                                <a className="addcart" href="#"><i className="fa fa-shopping-cart"></i>Add </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6">
                            <div className="product-cart-wrap style-2 wow animate__ animate__fadeInUp animated" data-wow-delay=".3s" style={{visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeInUp'}}>
                                <div className="product-img-action-wrap">
                                    <div className="product-img">
                                        <a href="shop-product-right.html">
                                            <img src="assets/deals/deal-1.png" alt="" />
                                        </a>
                                    </div>
                                </div>
                                <div className="product-content-wrap">
                                    <CountdownTimer countdownTimestampMs={1659983662000}/>
                                    <div className="deals-content">
                                        <h2><a href="shop-product-right.html">Simply Lemonade with Raspberry Juice</a></h2>
                                        <div>
                                            <span className="font-small text-muted">By <a href="vendor-details-1.html">Yoplait</a></span>
                                        </div>
                                        <div className="product-card-bottom">
                                            <div className="product-price">
                                                <span>₹ 15.85</span>
                                                <span className="old-price">₹ 16.8</span>
                                            </div>
                                            <div className="add-cart">
                                                <a className="addcart" href="#"><i className="fa fa-shopping-cart"></i>Add </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Deals