import React from 'react'
import "./service.css";

const Service = () => {
    return (
        <div className="container">
            <div className="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col_jgpm mb-4  col-style">
                    <div class="block-service-home6">
                        <ul>
                            <li class="item free-ship">
                                <div class="wrap">
                                    <div class="icon">
                                        <img src="assets/service/free.png" alt="" />
                                    </div>
                                    <div class="text">
                                        <h5><a href="#">Free Delivery</a></h5>
                                        <p>From $59.89</p>
                                    </div>
                                </div>
                            </li>
                            <li class="item support">
                                <div class="wrap">
                                    <div class="icon">
                                        <img src="assets/service/support.png" alt="" />
                                    </div>
                                    <div class="text">
                                        <h5><a href="#">Support 24/7</a></h5>
                                        <p>Online 24 hours</p>
                                    </div>
                                </div>
                            </li>
                            <li class="item free-return">
                                <div class="wrap">
                                    <div class="icon">
                                        <img src="assets/service/free-turn.png" alt="" />
                                    </div>
                                    <div class="text">
                                        <h5><a href="#">Free return</a></h5>
                                        <p>365 a day</p>
                                    </div>
                                </div>
                            </li>
                            <li class="item payment">
                                <div class="wrap">
                                    <div class="icon">
                                        <img src="assets/service/payment.png" alt="" />
                                    </div>
                                    <div class="text">
                                        <h5><a href="#">payment method</a></h5>
                                        <p>Secure payment</p>
                                    </div>
                                </div>
                            </li>
                            <li class="item big-saving">
                                <div class="wrap">
                                    <div class="icon">
                                        <img src="assets/service/big-save.png" alt="" />
                                    </div>
                                    <div class="text">
                                        <h5><a href="#">Big Saving</a></h5>
                                        <p>Weeken Sales</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>																	
				</div>
            </div>
        </div>
    )
}

export default Service