import "./brand.css";
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { toast } from "react-toastify";


const Brand = () => {
    var bsettings = {
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        initialSlide: 1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: false,
                initialSlide: 1,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
        ]
    };

    const [brand, setBrand] = useState([]);
    const baseUrl = "https://backendsk.skinrange.com/assets/brand/";
    useEffect(() => {
        getBrand()
    }, []);

    const getBrand = async () =>{
        try {
            const brandresponse = await axios({
                method:"GET",
                url:"https://backendsk.skinrange.com/api/apibrand",
                header: { "Content-Type": "multipart/form-data" }
            })
            if(brandresponse.data.status === "success"){
                setBrand(...[brandresponse.data.branddata])
            }
            else{
                toast.error("Unable to fetched Brand")
            }
        } 
        catch (error) {
            toast.error("Check Connection")
        }
    }

    return (
        <div className="homeBrand section suggest-part mb-4">
            <div className="container">
                <div class="row">
                    <div class="col-12">
                        <div class="brand-heading">
                            <h3>Shop By Brands</h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <Slider {...bsettings} className="homeBrandContainer">
                        {brand?.map((brand)=>(
                            <div className="brandItem col-md-4" key={brand}>
                                <Link className="suggest-brand" to="/">
                                    <img className="brandImg" src={baseUrl + brand.brand} alt={brand.brand} />
                                </Link>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export default Brand
