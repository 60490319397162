import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import Fsection2 from '../../components/footersection/fsection2/Fsection2'
import Navbar from '../../components/navbar/Navbar'
import Topbar from '../../components/topbar/Topbar'
import Footer from '../footer/Footer'
import "./pagenotfound.css";

const PageNotFound = () => {
    return (
        <div className="pagenotfound">
            <Helmet>
                <title>Skinrange::Page Not Found</title>
            </Helmet>
            <Topbar />
            <Navbar />
            <main className="main page-404">
                <div className="page-content pt-150 pb-150">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-8 col-lg-10 col-md-12 m-auto text-center">
                                <p className="mb-20">
                                    <img src="assets/p404/page-404.png" alt="" className="hover-up" />
                                </p>
                                <h1 className="display-2 mb-30">Page Not Found</h1>
                                <p className="font-lg text-grey-700 mb-30">
                                    The link you clicked may be broken or the page may have been removed.<br />
                                    visit the <Link to="/"> <span className="homepage"> Homepage</span></Link> or <Link to="/contact-us"><span className="homepage">Contact us</span></Link> about the problem
                                </p>
                                <Link className="btnpage btn-default submit-auto-width font-xs hover-up mt-30" to="/">
                                    <i className="fa fa-home"></i> Back To Home Page</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Fsection2 />
            <Footer />
        </div>
    )
}

export default PageNotFound