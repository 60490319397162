import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/home/Home";
import Product from "./pages/product/Product";
import Bestseller from "./pages/bestseller/Bestseller";
import Popular from "./pages/popular/Popular";
import About from "./pages/about/About";
import Register from "./pages/register/Register";
import Login from "./pages/login/Login";
import Contact from "./pages/contact/Contact";
import Blog from "./pages/blog/Blog";
import Faq from "./pages/faq/Faq";
import Term from "./pages/termcondition/TermCondition";
import Privacy from "./pages/privacypolicy/PrivacyPolicy";
import ReturnPolicy from "./pages/returnpolicy/ReturnPolicy";
import Delivery from "./pages/delivery/DeliveryPolicy";
import "./App.css";
import BlogDetail from "./pages/blog/BlogDetail";
import Cart from "./pages/cart/Cart";
import Checkout from "./pages/checkouts/Checkout";
import UserDashboard from "./pages/user/UserDashboard";
import ProductDetails from "./pages/productdetails/ProductDetails";
import PageNotFound from "./pages/pagenotfound/PageNotFound";
import Otp from "./pages/otp/Otp";
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CartProvider } from "react-use-cart";
import Payment from "./pages/payment/Payment";

function App() {
  return (
    <>
    <CartProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}  />
          <Route path="/product" element={<Product />}  />
          <Route path="/best-seller" element={<Bestseller />}  />
          <Route path="/popular" element={<Popular />}  />
          <Route path="/about-us" element={<About />}  />
          <Route path="/register" element={<Register />}  />
          <Route path="/login" element={<Login />}  />
          <Route path="/otp" element={<Otp />}  />
          <Route path="/contact-us" element={<Contact />}  />
          <Route path="/blog" element={<Blog />}  />
          <Route path="/faq" element={<Faq />}  />
          <Route path="/term-condition" element={<Term />}  />
          <Route path="/privacy-policy" element={<Privacy />}  />
          <Route path="/return-policy" element={<ReturnPolicy />}  />
          <Route path="/delivery-policy" element={<Delivery />}  />
          <Route path="/blog/:slug" element={<BlogDetail />}  />
          <Route path="/cart" element={<Cart />}  />
          <Route path="/checkout" element={<Checkout />}  />
          <Route path="/user-dashboard" element={<UserDashboard />}  />
          <Route path="/product/:slug" element={<ProductDetails />}  />
          <Route path="/payment" element={<Payment />}  />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </CartProvider>
    <ToastContainer/>
    </>
  );
}

export default App;
