import axios from 'axios';
import React from 'react'
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import "./offermiddle.css";

const Offermiddle = () => {
    const [offer, setOffer] = useState([])
    const baseUrl = "https://backendsk.skinrange.com/assets/offer/";
    useEffect(() => {
        getOfferBanner()
    }, []);
    const getOfferBanner = async () => {
        try {
            const offerresponse = await axios({
                method: "GET",
                url: "https://backendsk.skinrange.com/api/apiofferbanner",
                header: { "Content-Type": "multipart/form-data" }
            })
            if (offerresponse.data.status === "success") {
                setOffer(...[offerresponse.data.fbanner])
            }
            else {
                toast.error("Unable to fetched Offer Banner")
            }
        }
        catch (error) {
            toast.error("Check Connection")
        }
    }
    return (
        <section className="middleofferconatiner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="middlebanner mb-4">
                            <img src="https://backendsk.skinrange.com/assets/offer/offer-middle-banner2.jpg" alt="offer-middle-banner2.jpg" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Offermiddle