import React, { useState, useEffect } from 'react'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import { useCart } from 'react-use-cart'
import Fsection1 from '../../components/footersection/fsection1/Fsection1'
import Navbar from '../../components/navbar/Navbar'
import Service from '../../components/service/Service'
import Topbar from '../../components/topbar/Topbar'
import Footer from '../footer/Footer'
import "./checkout.css";
import { numberFormat } from '../../components/numberFormat'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'


const Checkout = () => {
    const navigate = useNavigate();

    const {
        totalUniqueItems,
        cartTotal,
        emptyCart,
        items,
    } = useCart();
    const cartdata = JSON.stringify(items);

    const [state, setState] = useState({
        name: "",
        email: "",
        country: "",
        state: "",
        city: "",
        pincode: "",
        address: "",
        paymenoption: ""
    })
    const userdata = localStorage.getItem('userdata');
    useEffect(() => {
        if (userdata) {
            getSingleUserdata();
        }
    }, [])
    const getSingleUserdata = async () => {
        const userid = JSON.parse(localStorage.getItem('userdata')).user_id;
        if (userid) {
            try {
                const userresponse = await axios({
                    method: "GET",
                    url: `https://backendsk.skinrange.com/api/apiget-single-userdata/${userid}`,
                    headers: { "Content-Type": "multipart/form-data" },
                })
                if (userresponse.data.status === "success") {
                    setState(...[userresponse.data.userdata])
                }
                else {
                    toast.error("Unable to fetched data")
                }
            }
            catch (error) {
                toast.error("Unable to login")
            }
        }
        else {
            toast.error("Please check your connection")
        }
    }


    const handleCheckout = async (e) => {
        e.preventDefault();
        if (state.paymenoption) {
            try {
                const checkoutresponse = await axios({
                    method: "POST",
                    url: "https://backendsk.skinrange.com/api/apicodcheckout",
                    data: { name: state.name, email: state.email, mobile: state.mobile, country: state.country, state: state.state, city: state.city, pincode: state.pincode, address: state.address, paymenoption: state.paymenoption, cartdata: cartdata, totalamt: cartTotal, discount: "100" },
                    headers: { "Content-Type": "multipart/form-data" },
                })
                if (checkoutresponse.data.status === "success") {
                    toast.success(checkoutresponse.data.message);
                    emptyCart();
                    navigate('/user-dashboard');
                }
                else {
                    toast.success(checkoutresponse.data.message);
                    navigate('/user-dashboard');
                }
            }
            catch (error) {
                toast.error("Server internal error!!");
            }
        }
        else {
            toast.error("All fields are required!!");
        }
    }

    const handleInputs = e => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    return (
        <div className="checkout">
            <Helmet>
                <title>Skinrange::Checkout</title>
            </Helmet>
            <Topbar />
            <Navbar />
            <section className="inner-section single-banner" style={{ backgroundImage: `url("assets/offer/offer-middle-banner.jpg")` }}>
                <div className="container">
                    <h2>Checkout</h2>
                </div>
            </section>
            <section className="inner-section checkout-part mt-4">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="account-card">
                                <div className="account-title">
                                    <h4>Checkout Option</h4>
                                </div>
                                <div className="account-content">
                                    <div className="row">
                                        {
                                            localStorage.getItem('userdata') ? ""
                                                :
                                                <div className="col-md-6 mb-3">
                                                    <div class="toggle_info">
                                                        <span><i class="fa fa-user"></i><span class="text-muted font-lg"> Already have an account?</span> <Link to="/login" data-bs-toggle="collapse" class="collapsed font-lg" aria-expanded="false">Click here to login</Link></span>
                                                    </div>
                                                </div>
                                        }
                                        <div className="col-md-6 mb-3">
                                            <form method="post" class="apply-coupon">
                                                <input type="text" placeholder="Enter Coupon Code..." />
                                                <button class="btn  btn-md" name="login">Apply Coupon</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <form onSubmit={handleCheckout} method="post">
                                <div className="account-card">
                                    <div className="account-title">
                                        <h4>Billing address</h4>
                                    </div>
                                    <div className="account-content">

                                        <div className="row">
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="name">name</label>
                                                <input type="text" className="form-control" id="name" name="name" onChange={handleInputs} placeholder="Name" value={state.name} required="true" />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="email">Email</label>
                                                <input type="email" className="form-control" id="email" name="email" value={state.email} onChange={handleInputs} required="true" placeholder="Email" />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="mobile">Mobile</label>
                                                <input type="text" onChange={handleInputs} className="form-control" id="mobile" placeholder="Mobile" userphone="true" value={state.mobile} name="mobile" required="true" />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="city">City</label>
                                                <input type="text" onChange={handleInputs} className="form-control" value={state.city} id="city" name="city" placeholder="City" required="true" />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="state">State</label>
                                                <input type="text" onChange={handleInputs} className="form-control" id="state" name="state" placeholder="State" value={state.state} required="true" />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="country">Country</label>
                                                <input type="text" onChange={handleInputs} className="form-control" id="country" name="country" placeholder="Country" value={state.country} required="true" />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="pincode">Pincode</label>
                                                <input type="text" onChange={handleInputs} className="form-control" id="pincode" name="pincode" placeholder="Pincode" value={state.pincode} required="true" />
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <label htmlFor="address">Address</label>
                                                <textarea onChange={handleInputs} style={{ height: "100px" }} className="form-control" id="address" name="address" placeholder="Address" value={state.address} required="true"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="account-card">
                                    <div className="account-title">
                                        <h4>Payment Option</h4>
                                    </div>
                                    <div className="account-content">
                                        <div className="row">
                                            <div className="col-md-3 mb-3">
                                                <input onChange={handleInputs} type="radio" name="paymenoption" id="paymenoption" value="CCAvenue" required="true" />
                                                <label className="payment-option" htmlFor="paymenoption">CCAvenue MCPGA</label>
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <input onChange={handleInputs} type="radio" name="paymenoption" id="paymenoption" value="Phonepe" required="true" />
                                                <label className="payment-option" htmlFor="paymenoption">Phonepe(Wallet/UPI)</label>
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <input onChange={handleInputs} type="radio" name="paymenoption" id="paymenoption" value="Stripe" required="true" />
                                                <label className="payment-option" htmlFor="paymenoption">Stripe Payment</label>
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <input onChange={handleInputs} type="radio" name="paymenoption" id="paymenoption" value="COD" required="true" />
                                                <label className="payment-option" htmlFor="paymenoption">COD(Cash On Delivery)</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="checkout-proced">
                                        <button style={{ width: "100%" }} type="submit" className="btn btn-checkout"> proceed to order</button>
                                    </div>

                                </div>
                            </form>
                        </div>
                        <div className="col-lg-4">
                            <div className="account-card">
                                <div className="account-title">
                                    <h4 className="d-flex justify-content-between align-items-center mb-3">
                                        <span className="text-muted">Your cart</span>
                                        <span className="badge-secondary badge-pill">{totalUniqueItems}</span>
                                    </h4>
                                </div>
                                <div className="account-content">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <ul className="list-group mb-3 sticky-top" style={{ zIndex: '1' }}>
                                                <li className="list-group-item d-flex justify-content-between lh-condensed">
                                                    <div>
                                                        <h6 className="my-0">Subtotal</h6>
                                                    </div>
                                                    <span className="text-muted">{numberFormat(cartTotal)}</span>
                                                </li>
                                                <li className="list-group-item d-flex justify-content-between lh-condensed">
                                                    <div>
                                                        <h6 className="my-0">GST</h6>
                                                    </div>
                                                    <span className="text-muted">₹0</span>
                                                </li>
                                                <li className="list-group-item d-flex justify-content-between lh-condensed">
                                                    <div>
                                                        <h6 className="my-0">SGST</h6>
                                                    </div>
                                                    <span className="text-muted">₹0</span>
                                                </li>
                                                <li className="list-group-item d-flex justify-content-between lh-condensed">
                                                    <div>
                                                        <h6 className="my-0">Discount</h6>
                                                    </div>
                                                    <span className="text-muted">₹0</span>
                                                </li>
                                                <li className="list-group-item d-flex justify-content-between">
                                                    <span>Total (INR)</span>
                                                    <strong>{numberFormat(cartTotal)}</strong>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Fsection1 />
            <Service />
            <Footer />
        </div>
    )
}

export default Checkout