import React, { useState, useEffect } from 'react'
import Helmet from 'react-helmet';
import './userdashboard.css';
import Navbar from '../../components/navbar/Navbar';
import Topbar from '../../components/topbar/Topbar';
import Footer from '../footer/Footer';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';

const initialState = {
    name: '',
    email: '',
    country: '',
    state: '',
    city: '',
    pincode: '',
    address: '',
}

const UserDashboard = () => {
    const navigate = useNavigate()
    const baseurl = 'https://backendsk.skinrange.com/assets/user/';
    const [file, setFile] = useState('');
    const [values, setInputValues] = useState(initialState);

    const userid = JSON.parse(localStorage.getItem('userdata')).user_id;
    useEffect(() => {
        const userid = JSON.parse(localStorage.getItem('userdata')).user_id;
        if (userid) {
            getSingleUserdata(userid)
        }
        else {
            navigate("/login");
        }

    }, [userid]);

    const getSingleUserdata = async (userid) => {
        if (userid) {
            try {
                const userresponse = await axios({
                    method: "GET",
                    url: `https://backendsk.skinrange.com/api/apiget-single-userdata/${userid}`,
                    headers: { "Content-Type": "multipart/form-data" },
                })
                if (userresponse.data.status === "success") {
                    setInputValues(...[userresponse.data.userdata])
                }
                else {
                    toast.error("Unable to fetched data")
                }
            }
            catch (error) {
                toast.error("Unable to login")
            }
        }
        else{
            toast.error("Please check your connection")
        }
    }
    const updateUserProfile = async (e) => {
        e.preventDefault();
        try {
            const updateresponse = await axios({
                method: "POST",
                url: `https://backendsk.skinrange.com/api/apiupdate-user-profile/${userid}`,
                data: values ,
                headers: { "Content-Type": "multipart/form-data" },
            })
            if (updateresponse.data.status === "success") {
                toast.success("Profile has been updated successfully!!")
            }
            else {
                toast.error("Unable to update your profile!!")
            }
        }
        catch (error) {
            toast.error("Connection failed!!")
        }
    }
    // Update Image
    useEffect(() => {
        const getImage = async() =>{
            if(file){
                try {
                    const data = new FormData();
                    data.append("foto", file);
                    const response = await axios({
                        method:"POST",
                        url:`https://backendsk.skinrange.com/api/apiupdate-user-profile-picture/${userid}`,
                        data:data,
                    })
                    if(response.data.status === "success"){
                        getSingleUserdata(userid);
                        toast.success("Profile Picture has been updated!");
                    }
                    else{
                        toast.error("Unable to update profile picture!!")
                    }
                } 
                catch (error) {
                    toast.error("Connection failed!!")
                }   
            }
        }
        getImage();
    }, [file]);

    const handleInputs = e =>{
        setInputValues({
            ...values,
            [e.target.name]:e.target.value,
        })
    }
    function logout() {
        localStorage.clear();
        navigate("/login");
    }
    return (
        <div className="userdashboard">
            <Helmet>
                <title>Skinrange::User Dashboard</title>
            </Helmet>
            <Topbar />
            <Navbar />
            <section className="inner-section single-banner" style={{ backgroundImage: `url("assets/offer/offer-middle-banner.jpg")` }}>
                <div className="container">
                    <h2>User Dashboard</h2>
                </div>
            </section>
            <section className="contact-page mt-4" style={{ paddingTop: '0px', paddingBottom: '40px' }}>
                <div className="contact-info-form-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 col-sm-12">
                                <div className="right-sideabr">
                                    <h4>Dashboard</h4>
                                    <ul className="list-item">
                                        <li><Link className="active" to="/">My Profile</Link></li>
                                        <li><Link to="/">My Order</Link></li>
                                        <li><a href="javascript:void(0)" onClick={logout}>Sing Out</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-9 col-md-8">
                                <h3 className="fomes">Edit User Profile</h3>
                                <div className="contact-form-div">
                                    <form onSubmit={updateUserProfile} encType="multipart/form-data" id="myform" method="post">
                                        <div className="row">
                                            <div className="col-lg-3 col-md-12 col-sm-3">
                                                <img className="userprofile" src={baseurl + values.foto} /><br />
                                                <label htmlFor='foto' className="profilelabel"><i class="fa fa-upload"></i> Upload Picture</label>
                                                <input type="file" onChange={(e) => setFile(e.target.files[0])} id='foto' name="foto" style={{ marginBottom: '20px', marginTop: '5px', display: 'none' }} />
                                                <input type="hidden" name="fotos" />
                                            </div>
                                            <div className="col-md-9">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-12 col-sm-12">
                                                        <label>User Name</label>
                                                        <input name="name" id="name" onChange={handleInputs} placeholder="Enter here..." className="form-control" type="text" required="true" value={values.name} />
                                                    </div>
                                                    <div className="col-lg-6 col-md-12 col-sm-12">
                                                        <label>Email Id</label>
                                                        <input placeholder="Enter here..." type="email" name="email" id="email" onChange={handleInputs} className="form-control" value={values.email} required="true" />
                                                    </div>
                                                    <div className="col-lg-6 col-md-12 col-sm-12">
                                                        <label>Mobile</label>
                                                        <input placeholder="Enter here..." type="text" name="mobile" id="mobile" className="form-control" value={values.mobile} readOnly="true" />
                                                    </div>
                                                    <div className="col-lg-6 col-md-12 col-sm-12">
                                                        <label>City</label>
                                                        <input placeholder="City" type="text" name="city" id="city" className="form-control" onChange={handleInputs} required="true" value={values.city} />
                                                    </div>
                                                    <div className="col-lg-6 col-md-12 col-sm-12">
                                                        <label>State</label>
                                                        <input placeholder="State" type="text" name="state" id="state" required="true" onChange={handleInputs} className="form-control" value={values.state} />
                                                    </div>
                                                    <div className="col-lg-6 col-md-12 col-sm-12">
                                                        <label>Pincode</label>
                                                        <input placeholder="Pincode" type="text" name="pincode" id="pincode" required="true" onChange={handleInputs} className="form-control" value={values.pincode} />
                                                    </div>
                                                    <div className="col-lg-6 col-md-12 col-sm-12">
                                                        <label>Country</label>
                                                        <input placeholder="Country" type="text" name="country" id="country" required="true" onChange={handleInputs} value={values.country} className="form-control" />
                                                    </div>
                                                    <div className="col-lg-6 col-md-12 col-sm-12">
                                                        <label>Address</label>
                                                        <textarea type="text" name="address" id="address" className="form-control" required="true" onChange={handleInputs} value={values.address}></textarea>
                                                    </div>
                                                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                                                        <button className="register-button mt-0">Submit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default UserDashboard