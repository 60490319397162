import React from 'react'
import {Helmet} from "react-helmet";
import Navbar from '../../components/navbar/Navbar'
import Topbar from '../../components/topbar/Topbar'
import Footer from '../footer/Footer'

const About = () => {
    return (
        <div>
            <Helmet>
                <title>Skinrange::About us</title>
            </Helmet>

            <Topbar />
            <Navbar />
            <section>
                <h3>This is About us section</h3>
            </section>
            <Footer />
        </div>
    )
}

export default About
