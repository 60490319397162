import React from 'react'
import Helmet from 'react-helmet'
import Navbar from '../../components/navbar/Navbar'
import Topbar from '../../components/topbar/Topbar'
import Footer from '../footer/Footer'
import "./register.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

const Register = () => {
    return (
        <div>
            <Helmet>
                <title>Skinrange::Register</title>
            </Helmet>
            <Topbar />
            <Navbar />
            <section className="inner-section single-banner" style={{backgroundImage: `url("assets/offer/offer-middle-banner.jpg")`}}>
                <div className="container">
                    <h2>Register</h2>
                </div>
            </section>
            <section className="user-form-part">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-sm-5 col-md-6 col-lg-6 col-xl-5">
                            <div className="user-form-card">
                                <div className="user-form-title">
                                    <h2>Register</h2>
                                </div>
                                <div className="user-form-group">
                                    <form className="user-form" id="registerForm" method="post" action="#" noValidate="novalidate">
                                        <div className="form-group">
                                            <label htmlFor="name">Name:</label>
                                            <input type="text" name="name" id="name" className="form-control" placeholder="Enter your Name" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="email">Email:</label>
                                            <input type="email" name="email" id="email" className="form-control" placeholder="Enter your email" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="mobile">Mobile:</label>
                                            <input type="tel" name="mobile" id="mobile" userphone="true" className="form-control" placeholder="Enter your Mobile" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="name">Password:</label>
                                            <input type="password" name="password" id="password" className="form-control" placeholder="Enter your password" />
                                        </div>
                                        <div className="form-button">
                                            <button className="register">Register</button>
                                        </div>
                                        <div className="form-button">
                                            <Link to="/login"><p>If you have already account?<a href="#">Login here</a></p></Link>
                                        </div>
                                        <div className="sociallogin">
                                            <h1><span>Or Continue with</span></h1>
                                            <div className="form-button socialloginbtn">
                                                <button className="google"><img src="assets/social/google_logo.svg" alt="" /> Login in with google</button>
                                                <button className="facebook"><img src="assets/social/facebook_logo.svg" alt="" /> Login in with facebook</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Register