import React, { useState } from 'react'
import { Helmet } from 'react-helmet';
import { Link} from 'react-router-dom';
import Navbar from '../../components/navbar/Navbar';
import Topbar from '../../components/topbar/Topbar';
import Footer from '../footer/Footer';
import "./productdetails.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStarHalfAlt } from '@fortawesome/free-solid-svg-icons'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const ProductDetails = () => {
    var pdsettings = {
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        initialSlide: 1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                initialSlide: 1,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
        ]
    };
    var pdbsettings = {
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: false,
        initialSlide: 1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                initialSlide: 1,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
        ]
    };
    const [key, setKey]  = useState('home');
    return (
        <div className="productDetails">
            <Helmet>
                <title>Skinrange::Product Details</title>
            </Helmet>
            <Topbar />
            <Navbar />
            <main className="main">
                <div className="page-header breadcrumb-wrap">
                    <div className="container">
                        <div className="breadcrumb">
                            <Link to="/" rel="nofollow">Home</Link> <i class="fa fa-angle-right" aria-hidden="true"></i> 
                            <Link to="/product"> Vegetables & tubers</Link> <i class="fa fa-angle-right" aria-hidden="true"></i> Seeds of Change Organic
                        </div>
                    </div>
                </div>
                <div className="container mt-4 mb-30">
                    <div className="row">
                        <div className="col-xl-10 col-lg-12 m-auto">
                            <div className="product-detail accordion-detail">
                                <div className="row mb-50 mt-30">
                                    <div className="col-md-6 col-sm-12 col-xs-12 mb-md-0 mb-sm-5">
                                        <div className="detail-gallery">
                                            <div className="product-image-slider">
                                                <Slider {...pdsettings}>
                                                <figure className="border-radius-10 pdimg">
                                                    <img src={window.location.origin + "/assets/product/01.jpg"} alt="product image" />
                                                </figure>
                                                <figure className="border-radius-10 pdimg">
                                                    <img src={window.location.origin + "/assets/product/02.jpg"} alt="product image" />
                                                </figure>
                                                <figure className="border-radius-10 pdimg">
                                                    <img src={window.location.origin + "/assets/product/03.jpg"} alt="product image" />
                                                </figure>
                                                <figure className="border-radius-10 pdimg">
                                                    <img src={window.location.origin + "/assets/product/04.jpg"} alt="product image" />
                                                </figure>
                                                <figure className="border-radius-10 pdimg">
                                                    <img src={window.location.origin + "/assets/product/05.jpg"} alt="product image" />
                                                </figure>
                                                </Slider>
                                            </div>

                                            {/* //THUMBNAILS  */}
                                            <div className="slider-nav-thumbnails">
                                                <Slider {...pdbsettings}>
                                                <div>
                                                    <img className="psbimg" src={window.location.origin + "/assets/product/01.jpg"} alt="product image" />
                                                </div>
                                                <div>
                                                    <img className="psbimg" src={window.location.origin + "/assets/product/02.jpg"} alt="product image" />
                                                </div>
                                                <div>
                                                    <img className="psbimg" src={window.location.origin + "/assets/product/03.jpg"} alt="product image" />
                                                </div>
                                                <div>
                                                    <img className="psbimg" src={window.location.origin + "/assets/product/04.jpg"} alt="product image" />
                                                </div>
                                                <div>
                                                    <img className="psbimg" src={window.location.origin + "/assets/product/05.jpg"} alt="product image" />
                                                </div>
                                                <div>
                                                    <img className="psbimg" src={window.location.origin + "/assets/product/06.jpg"} alt="product image" />
                                                </div>
                                                <div>
                                                    <img className="psbimg" src={window.location.origin + "/assets/product/07.jpg"} alt="product image" />
                                                </div>
                                                <div>
                                                    <img className="psbimg" src={window.location.origin + "/assets/product/07.jpg"} alt="product image" />
                                                </div>
                                                </Slider>
                                            </div>
                                        </div>
                                        {/*  End Gallery  */}
                                    </div>
                                    <div className="col-md-6 col-sm-12 col-xs-12 detail">
                                        <div className="detail-info pr-30 pl-30 mt-4">
                                            <h2 className="title-detail">Seeds of Change Organic Quinoa, Brown</h2>
                                            <div className="clearfix product-price-cover">
                                                <div className="product-price primary-color float-left">
                                                    <span className="current-price text-brand">₹ 38</span>
                                                    <span>
                                                        <span className="old-price font-md ml-15">₹ 52</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="short-desc mb-30">
                                                <p className="font-lg">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aliquam rem officia, corrupti reiciendis minima nisi modi, quasi, odio minus dolore impedit fuga eum eligendi.
                                                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veniam nemo veritatis, totam ut maxime minus, quidem, sit culpa fugiat ab magni iure voluptate natus dicta nulla iste. Odit, numquam eos?
                                                </p>
                                            </div>
                                            <div className="detail-extralink mb-50">
                                                <div className="product-extra-link2">
                                                    <button className="button button-add-to-cart btncart"><i className="fa fa-shopping-cart"></i> Add to cart</button>
                                                    <button className="button button-add-to-cart btncart"><i className="fa fa-shopping-cart"></i> Buy Now</button>
                                                </div>
                                            </div>
                                            <div className="font-xs">
                                                <ul className="mr-50 float-start">
                                                    <li className="pdcat">Category: <span className="text-brand">Organic</span></li>
                                                    <li className="pdcat">Brand:<span className="text-brand"> Aush</span></li>
                                                    <li className="pdcat">SKU: <a href="#">FWM15VKT</a></li>
                                                    <li className="pdcat">Tags: <a href="#" rel="tag">Snack</a>, <a href="#" rel="tag">Organic</a>, <a href="#" rel="tag">Brown</a></li>
                                                    <li className="pdcat">Stock:<span className="in-stock text-brand ml-5">8 Items In Stock</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/* Detail Info */}
                                    </div>
                                </div>
                                <div className="product-info">
                                    <div className="tab-style3">
                                    <Tabs>
                                        <TabList className="nav nav-tabs text-uppercase">
                                            <Tab className="nav-item">
                                                <a className="nav-link" id="Description-tab" data-bs-toggle="tab">Description</a>
                                            </Tab>
                                            <Tab className="nav-item">
                                                <a className="nav-link" id="Additional-info-tab" data-bs-toggle="tab">Additional info</a>
                                            </Tab>
                                            <Tab className="nav-item">
                                                <a className="nav-link" id="Reviews-tab" data-bs-toggle="tab">Reviews</a>
                                            </Tab>
                                        </TabList>
                                        <div className="tab-content shop_info_tab entry-main-content">
                                            <TabPanel>
                                            <div className="">
                                                    <p>Uninhibited carnally hired played in whimpered dear gorilla koala depending and much yikes off far quetzal goodness and from for grimaced goodness unaccountably and meadowlark near unblushingly crucial scallop tightly neurotic hungrily some and dear furiously this apart.</p>
                                                    <p>Spluttered narrowly yikes left moth in yikes bowed this that grizzly much hello on spoon-fed that alas rethought much decently richly and wow against the frequent fluidly at formidable acceptably flapped besides and much circa far over the bucolically hey precarious goldfinch mastodon goodness gnashed a jellyfish and one however because.</p>
                                                    <ul className="product-more-infor mt-30">
                                                        <li><span>Type Of Packing</span> Bottle</li>
                                                        <li><span>Color</span> Green, Pink, Powder Blue, Purple</li>
                                                        <li><span>Quantity Per Case</span> 100ml</li>
                                                        <li><span>Ethyl Alcohol</span> 70%</li>
                                                        <li><span>Piece In One</span> Carton</li>
                                                    </ul>
                                                    <hr className="wp-block-separator is-style-dots" />
                                                    <p>Laconic overheard dear woodchuck wow this outrageously taut beaver hey hello far meadowlark imitatively egregiously hugged that yikes minimally unanimous pouted flirtatiously as beaver beheld above forward energetic across this jeepers beneficently cockily less a the raucously that magic upheld far so the this where crud then below after jeez enchanting drunkenly more much wow callously irrespective limpet.</p>
                                                    <h4 className="mt-30">Packaging & Delivery</h4>
                                                    <hr className="wp-block-separator is-style-wide" />
                                                    <p>Less lion goodness that euphemistically robin expeditiously bluebird smugly scratched far while thus cackled sheepishly rigid after due one assenting regarding censorious while occasional or this more crane went more as this less much amid overhung anathematic because much held one exuberantly sheep goodness so where rat wry well concomitantly.</p>
                                                    <p>Scallop or far crud plain remarkably far by thus far iguana lewd precociously and and less rattlesnake contrary caustic wow this near alas and next and pled the yikes articulate about as less cackled dalmatian in much less well jeering for the thanks blindly sentimental whimpered less across objectively fanciful grimaced wildly some wow and rose jeepers outgrew lugubrious luridly irrationally attractively dachshund.</p>
                                                    <h4 className="mt-30">Suggested Use</h4>
                                                    <ul className="product-more-infor mt-30">
                                                        <li>Refrigeration not necessary.</li>
                                                        <li>Stir before serving</li>
                                                    </ul>
                                                    <h4 className="mt-30">Other Ingredients</h4>
                                                    <ul className="product-more-infor mt-30">
                                                        <li>Organic raw pecans, organic raw cashews.</li>
                                                        <li>This butter was produced using a LTG (Low Temperature Grinding) process</li>
                                                        <li>Made in machinery that processes tree nuts but does not process peanuts, gluten, dairy or soy</li>
                                                    </ul>
                                                    <h4 className="mt-30">Warnings</h4>
                                                    <ul className="product-more-infor mt-30">
                                                        <li>Oil separation occurs naturally. May contain pieces of shell.</li>
                                                    </ul>
                                                </div>
                                            </TabPanel>
                                            <TabPanel>
                                            <table className="font-md">
                                                    <tbody>
                                                        <tr className="stand-up">
                                                            <th>Stand Up</th>
                                                            <td>
                                                                <p>35″L x 24″W x 37-45″H(front to back wheel)</p>
                                                            </td>
                                                        </tr>
                                                        <tr className="folded-wo-wheels">
                                                            <th>Folded (w/o wheels)</th>
                                                            <td>
                                                                <p>32.5″L x 18.5″W x 16.5″H</p>
                                                            </td>
                                                        </tr>
                                                        <tr className="folded-w-wheels">
                                                            <th>Folded (w/ wheels)</th>
                                                            <td>
                                                                <p>32.5″L x 24″W x 18.5″H</p>
                                                            </td>
                                                        </tr>
                                                        <tr className="door-pass-through">
                                                            <th>Door Pass Through</th>
                                                            <td>
                                                                <p>24</p>
                                                            </td>
                                                        </tr>
                                                        <tr className="frame">
                                                            <th>Frame</th>
                                                            <td>
                                                                <p>Aluminum</p>
                                                            </td>
                                                        </tr>
                                                        <tr className="weight-wo-wheels">
                                                            <th>Weight (w/o wheels)</th>
                                                            <td>
                                                                <p>20 LBS</p>
                                                            </td>
                                                        </tr>
                                                        <tr className="weight-capacity">
                                                            <th>Weight Capacity</th>
                                                            <td>
                                                                <p>60 LBS</p>
                                                            </td>
                                                        </tr>
                                                        <tr className="width">
                                                            <th>Width</th>
                                                            <td>
                                                                <p>24″</p>
                                                            </td>
                                                        </tr>
                                                        <tr className="handle-height-ground-to-handle">
                                                            <th>Handle height (ground to handle)</th>
                                                            <td>
                                                                <p>37-45″</p>
                                                            </td>
                                                        </tr>
                                                        <tr className="wheels">
                                                            <th>Wheels</th>
                                                            <td>
                                                                <p>12″ air / wide track slick tread</p>
                                                            </td>
                                                        </tr>
                                                        <tr className="seat-back-height">
                                                            <th>Seat back height</th>
                                                            <td>
                                                                <p>21.5″</p>
                                                            </td>
                                                        </tr>
                                                        <tr className="head-room-inside-canopy">
                                                            <th>Head room (inside canopy)</th>
                                                            <td>
                                                                <p>25″</p>
                                                            </td>
                                                        </tr>
                                                        <tr className="pa_color">
                                                            <th>Color</th>
                                                            <td>
                                                                <p>Black, Blue, Red, White</p>
                                                            </td>
                                                        </tr>
                                                        <tr className="pa_size">
                                                            <th>Size</th>
                                                            <td>
                                                                <p>M, S</p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </TabPanel>
                                            <TabPanel>
                                            <div className="comments-area">
                                                    <div className="row">
                                                        <div className="col-lg-12 col-md-12">
                                                            <h4 className="mb-30">Customer questions & answers</h4>
                                                            <div className="comment-list">
                                                                <div className="single-comment justify-content-between d-flex mb-30">
                                                                    <div className="user justify-content-between d-flex">
                                                                        <div className="thumb text-center">
                                                                            <img src="http://localhost:3000/assets/product/02.jpg" alt="" />
                                                                            <a href="#" className="font-heading text-brand">Sienna</a>
                                                                        </div>
                                                                        <div className="desc">
                                                                            <div className="d-flex justify-content-between mb-10">
                                                                                <div className="d-flex align-items-center">
                                                                                    <span className="font-xs text-muted">December 4, 2021 at 3:12 pm </span>
                                                                                </div>
                                                                                <div className="product-rate d-inline-block">
                                                                                    <div className="product-rating" style={{width: '100%'}}></div>
                                                                                </div>
                                                                            </div>
                                                                            <p className="mb-10">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus, suscipit exercitationem accusantium obcaecati quos voluptate nesciunt facilis itaque modi commodi dignissimos sequi repudiandae minus ab deleniti totam officia id incidunt?</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="single-comment justify-content-between d-flex mb-30 ml-30">
                                                                    <div className="user justify-content-between d-flex">
                                                                        <div className="thumb text-center">
                                                                            <img src="http://localhost:3000/assets/product/01.jpg" alt="" />
                                                                            <a href="#" className="font-heading text-brand">Brenna</a>
                                                                        </div>
                                                                        <div className="desc">
                                                                            <div className="d-flex justify-content-between mb-10">
                                                                                <div className="d-flex align-items-center">
                                                                                    <span className="font-xs text-muted">December 4, 2021 at 3:12 pm </span>
                                                                                </div>
                                                                                <div className="product-rate d-inline-block">
                                                                                    <div className="product-rating" style={{width: "80%"}}></div>
                                                                                </div>
                                                                            </div>
                                                                            <p className="mb-10">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus, suscipit exercitationem accusantium obcaecati quos voluptate nesciunt facilis itaque modi commodi dignissimos sequi repudiandae minus ab deleniti totam officia id incidunt?</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="single-comment justify-content-between d-flex">
                                                                    <div className="user justify-content-between d-flex">
                                                                        <div className="thumb text-center">
                                                                            <img src="http://localhost:3000/assets/product/03.jpg" alt="" />
                                                                            <a href="#" className="font-heading text-brand">Gemma</a>
                                                                        </div>
                                                                        <div className="desc">
                                                                            <div className="d-flex justify-content-between mb-10">
                                                                                <div className="d-flex align-items-center">
                                                                                    <span className="font-xs text-muted">December 4, 2021 at 3:12 pm </span>
                                                                                </div>
                                                                                <div className="product-rate d-inline-block">
                                                                                    <div className="product-rating" style={{width: '80%'}}></div>
                                                                                </div>
                                                                            </div>
                                                                            <p className="mb-10">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus, suscipit exercitationem accusantium obcaecati quos voluptate nesciunt facilis itaque modi commodi dignissimos sequi repudiandae minus ab deleniti totam officia id incidunt?</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div className="comment-form">
                                                    <h4 className="mb-15">Add a review</h4>
                                                    <div className="product-rate d-inline-block mb-30"></div>
                                                    <div className="row">
                                                        <div className="col-lg-12 col-md-12">
                                                            <form className="form-contact comment_form" action="#" id="commentForm">
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <div className="form-group">
                                                                            <textarea className="form-control w-100" name="comment" id="comment" cols="30" rows="9" placeholder="Write Comment"></textarea>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-6">
                                                                        <div className="form-group">
                                                                            <input className="form-control" name="name" id="name" type="text" placeholder="Name" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-6">
                                                                        <div className="form-group">
                                                                            <input className="form-control" name="email" id="email" type="email" placeholder="Email" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <div className="form-group">
                                                                            <input className="form-control" name="website" id="website" type="text" placeholder="Website" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group">
                                                                    <button type="submit" className="button button-contactForm">Submit Review</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                        </div>
                                    </Tabs>
                                    </div>
                                </div>
                                <div className="row mt-60 mt-4">
                                    <div className="col-12">
                                        <h2 className="section-title style-1 mb-30">Related products</h2>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
                                            <div className="col">
                                                <div className="product-card">
                                                    <div className="product-media">
                                                        <a className="product-image" href="#">
                                                            <img src={window.location.origin + "/assets/product/02.jpg"} />
                                                        </a>
                                                    </div>
                                                    <div className="product-content">
                                                        <h6 className="product-name">
                                                            <a href="#">Arthorex</a>
                                                        </h6>
                                                        <div className="product-rating">
                                                            <span className="product-ratings">4.0 <FontAwesomeIcon className="active" icon={faStarHalfAlt} /></span>
                                                        </div>
                                                        <h6 className="product-price">
                                                            <del>₹ 1155</del>
                                                            <span>₹ 999</span>
                                                        </h6>
                                                        <div className="add-button">
                                                            <button productid="1" className="addtocart-button addToCart" title="Add to Cart"><i className="fas fa-shopping-basket"></i><span>Add To Cart</span></button> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                    
                                            <div className="col">
                                                <div className="product-card">
                                                    <div className="product-media">
                                                        <a className="product-image" href="#">
                                                            <img src={window.location.origin + "/assets/product/05.jpg"} />
                                                        </a>
                                                    </div>
                                                    <div className="product-content">
                                                        <h6 className="product-name">
                                                            <a href="#">Arthorex</a>
                                                        </h6>
                                                        <div className="product-rating">
                                                            <span className="product-ratings">4.0 <FontAwesomeIcon className="active" icon={faStarHalfAlt} /></span> 
                                                        </div>
                                                        <h6 className="product-price">
                                                            <del>₹ 1155</del>
                                                            <span>₹ 999</span>
                                                        </h6>
                                                        <div className="add-button">
                                                            <button productid="1" className="addtocart-button addToCart" title="Add to Cart"><i className="fas fa-shopping-basket"></i><span>Add To Cart</span></button> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                     
                                            <div className="col">
                                                <div className="product-card">
                                                    <div className="product-media">
                                                        <a className="product-image" href="#">
                                                            <img src={window.location.origin + "/assets/product/02.jpg"} />
                                                        </a>
                                                    </div>
                                                    <div className="product-content">
                                                        <h6 className="product-name">
                                                            <a href="#">Arthorex</a>
                                                        </h6>
                                                        <div className="product-rating">
                                                            <span className="product-ratings">4.0 <FontAwesomeIcon className="active" icon={faStarHalfAlt} /></span>
                                                        </div>
                                                        <h6 className="product-price">
                                                            <del>₹ 1155</del>
                                                            <span>₹ 999</span>
                                                        </h6>
                                                        <div className="add-button">
                                                            <button productid="1" className="addtocart-button addToCart" title="Add to Cart"><i className="fas fa-shopping-basket"></i><span>Add To Cart</span></button> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                     
                                            <div className="col">
                                                <div className="product-card">
                                                    <div className="product-media">
                                                        <a className="product-image" href="#">
                                                            <img src={window.location.origin + "/assets/product/04.jpg"} />
                                                        </a>
                                                    </div>
                                                    <div className="product-content">
                                                        <h6 className="product-name">
                                                            <a href="#">Arthorex</a>
                                                        </h6>
                                                        <div className="product-rating">
                                                            <span className="product-ratings">4.0 <FontAwesomeIcon className="active" icon={faStarHalfAlt} /></span>
                                                        </div>
                                                        <h6 className="product-price">
                                                            <del>₹ 1155</del>
                                                            <span>₹ 999</span>
                                                        </h6>
                                                        <div className="add-button">
                                                            <button productid="1" className="addtocart-button addToCart" title="Add to Cart"><i className="fas fa-shopping-basket"></i><span>Add To Cart</span></button> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                                          
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    )
}

export default ProductDetails