import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "./middlesection.css";

const MiddleSection = () => {
    const [mbanner, setMBanner] = useState([]);
    const baseUrl = "https://backendsk.skinrange.com/assets/banner/";
    useEffect(() => { 
        cbanner()
    }, []);

    const cbanner = async () => {
        try {
            const cbannerresponse = await axios({
                method:"GET",
                url:"https://backendsk.skinrange.com/api/apimiddlebanner",
                header: { "Content-Type": "multipart/form-data" }
            })
            if(cbannerresponse.data.status === "success"){
                setMBanner(...[cbannerresponse.data.cbanner])
            }
            else{
                toast.error("Unable to fetchd category banner!!")
            }
        }
        catch (error) {
            toast.error("Check connection")
        }
    };

    return (
        <div className="middlesection">
            <section className="banners mb-25">
                <div className="container">
                    <div className="row">
                        {mbanner?.map((mbanner)=>(
                            <div className="col-lg-4 col-md-6" key={mbanner}>
                                <div
                                    className="banner-img wow animate__ animate__fadeInUp animated"
                                    data-wow-delay="0"
                                    style={{ visibility: "visible", animationName: "fadeInUp" }}
                                >
                                    <img src={baseUrl + mbanner.mbanner} alt={mbanner.mbanner} />
                                    <div className="banner-text">
                                        <h4>
                                            Everyday Fresh &amp; <br />
                                            Clean with Our
                                            <br />
                                            Products
                                        </h4>
                                        <Link to="/product" className="btn btn-xs">
                                            Shop Now <i className="fa fa-arrow-right"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default MiddleSection;
