import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useCart } from 'react-use-cart'
import Fsection1 from '../../components/footersection/fsection1/Fsection1'
import Navbar from '../../components/navbar/Navbar'
import Service from '../../components/service/Service'
import Topbar from '../../components/topbar/Topbar'
import Footer from '../footer/Footer'
import "./cart.css";
import { numberFormat } from '../../components/numberFormat'

const Cart = () => {
    const {
        isEmpty,
        items,
        updateItemQuantity,
        cartTotal,
    } = useCart();

    const { removeItem } = useCart();
    const removeItems = (id) => {
        removeItem(id);
        toast.error("This product has been remove in your cart!");
    };
    const baseUrl = "https://backendsk.skinrange.com/assets/product/";

    return (
        <div className="cart">
            <Helmet>
                <title>Skinrange::Cart</title>
            </Helmet>
            <Topbar />
            <Navbar />
            <section className="inner-section single-banner" style={{backgroundImage: `url("assets/offer/offer-middle-banner.jpg")`}}>
                <div className="container">
                    <h2>Cart</h2>
                </div>
            </section>
            {isEmpty ? <div style={{marginBottom:"50px",display:"flex",justifyContent:"center"}}> <img src="assets/images/em-cart.webp" alt="em-cart.webp" /> </div> :
            
            <section className="inner-section checkout-part mt-4">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9">
                            <div className="account-card">
                                <div className="account-title">
                                    <h4>Your Cart</h4>
                                </div>
                                <div className="account-content">
                                    <div className="table-scroll">
                                        <table className="table-list">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Product</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Price</th>
                                                    <th scope="col">quantity</th>
                                                    <th scope="col">Sub Total</th>
                                                    <th scope="col">action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {items?.map((item , index)=>(
                                                <tr>
                                                    <td className="table-serial">
                                                        <h6>{index + 1}</h6>
                                                    </td>
                                                    <td className="table-image">
                                                        <img src={baseUrl + item.image} alt={item.image} />
                                                    </td>
                                                    <td className="table-name">
                                                        <h6>{item.name}</h6>
                                                    </td>
                                                    <td className="table-price">
                                                        <h6>₹ {item.price}</h6>
                                                    </td>
                                                    <td className="table-quantity">
                                                        <div className="cart-action-group">
                                                            <div class="product-action">
                                                                <button onClick={() => updateItemQuantity(item.id, item.quantity - 1)} className="action-minus" title="Quantity Minus"><i class="fa fa-minus"></i></button>
                                                                <input className="action-input" title="Quantity Number" type="text" name="quantity" id="quantity" value={item.quantity} pattern="[0-9]*" />
                                                                <button onClick={() => updateItemQuantity(item.id, item.quantity + 1)} className="action-plus" title="Quantity Plus"><i class="fa fa-plus"></i></button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="table-price">
                                                        <h6>{numberFormat(item.itemTotal)}</h6>
                                                    </td>
                                                    <td className="table-action">
                                                        <button onClick={() => removeItems(item.id)} className="trash" title="Remove Wishlist">
                                                            <i className="fa fa-trash"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="account-card">
                                <div className="account-content p-40">
                                    <h4 class="mb-10 text-center">Estimate Shipping & Tax</h4>
                                    <table className="table-list">
                                        <tbody>
                                            <tr>
                                                <td className="subtotal">Sub total</td>
                                                <td>{numberFormat(cartTotal)}</td>
                                            </tr>
                                            <tr>
                                                <td className="subtotal">Shipping Charge</td>
                                                <td>₹ 0.00</td>
                                            </tr>
                                            <tr>
                                                <td className="subtotal">GST</td>
                                                <td>₹ 0</td>
                                            </tr>
                                            <tr>
                                                <td className="subtotal">SGST</td>
                                                <td>₹ 0</td>
                                            </tr>
                                            <tr>
                                                <td className="subtotal">Discount</td>
                                                <td>₹ 0</td>
                                            </tr>
                                            <tr>
                                                <td className="subtotal">Total Price</td>
                                                <td><b>{numberFormat(cartTotal)}</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="btn-checkout">
                                        {
                                            localStorage.getItem('userdata') ?
                                            <>
                                            <Link to="/checkout" className="btn-checkout">Proceed to Checkout</Link>
                                            </>
                                            :
                                            <>
                                            <Link to="/login" className="btn-checkout">Proceed to Checkout</Link>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            }
            <Fsection1 />
            <Service />
            <Footer />
        </div>
    )
}

export default Cart