import React from 'react'
import { Link } from 'react-router-dom';
import "./footer.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFaceAngry } from '@fortawesome/free-regular-svg-icons';

const Footer = () => {
    return (
        <>
            <div className="footer">
                <div className="fLists">
                    <div className="col-lg-2 col-md-4 col-sm-4 col-xs-12 col_hokm  col-style">
                        <div className="footer-links">
                            <img src={window.location.origin + "/assets/images/skinrangelogo.png"} alt="footer-logo" className="footer-logo" />
                            <p className="mt-4">Address: 6th Floor, Unit Number 603, Mercantile House, K.G. Marg, New Delhi, Delhi, 110001</p>
                            <p>Phone:+91-9319299082</p>
                            <p>Email:info@skinrange.com</p>
                            <p><h4>Get in Touch:</h4></p>
                            <ul className="get-in-touch-links">
                                <li><Link to="/"><i className="fab fa-facebook-f social-btn"></i></Link></li>
                                <li><Link to="/"><i className="fab fa-twitter social-btn"></i></Link></li>
                                <li><Link to="/"><i className="fab fa-instagram social-btn"></i></Link></li>
                                <li><Link to="/"><i className="fab fa-pinterest social-btn"></i></Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-4 col-xs-12 col_hokm  col-style">
                        <div className="footer-links">
                            <h4>POLICY INFO</h4>
                            <ul className="fList">
                                <Link to="/term-condition"><li className="fListItem">Terms & Conditions</li></Link>
                                <Link to="/privacy-policy"><li className="fListItem">Privacy Policy</li></Link>
                                <Link to="/return-policy"><li className="fListItem">Refund & Cancellation Policy</li></Link>
                                <Link to="/delivery-policy"><li className="fListItem">Shipping / Delivery Policy</li></Link>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-4 col-xs-12 col_hokm  col-style">
                        <div className="footer-links">
                            <h4>Category</h4>
                            <ul className="fList">
                                <Link to=""><li className="fListItem">Diabetes</li></Link>
                                <li className="fListItem">Hair Fall</li>
                                <li className="fListItem">Sexual Wellness</li>
                                <li className="fListItem">Addiction</li>
                                <li className="fListItem">Joint Pain</li>
                                
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-4 col-xs-12 col_hokm  col-style">
                        <div className="footer-links">
                            <h4>Quick Link</h4>
                            <ul className="fList">
                                <Link to="/contact-us"><li className="fListItem">Contact us</li></Link>
                                <Link to="/faq"><li className="fListItem">FAQ</li></Link>
                                <Link to="/blog"><li className="fListItem">Blog</li></Link>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-4 col-xs-12 col_hokm  col-style">
                        <div className="insta-gellary ">
                            <h4>INSTARGRAM GALLERY</h4>
                            <div className="instagram-item">
                                <div className="instagram_users">
                                    <div className="img_users">
                                        <a title="insta1" data-href="#" className="instagram_gallery_image gallery_image_instagram10509608261653366570" rel="gallery" href="#">
                                            <img className="image_users" src={window.location.origin + "/assets/instagram/insta2.jpg"} title="insta1" alt="insta2" />
                                        </a>
                                    </div>
                                    <div className="img_users">
                                        <a title="insta1" data-href="#" className="instagram_gallery_image gallery_image_instagram10509608261653366570" rel="gallery" href="#">
                                            <img className="image_users" src={window.location.origin + "/assets/instagram/insta5.jpg"} title="insta1" alt="insta5" />
                                        </a>
                                    </div>
                                    <div className="img_users">
                                        <a title="insta1" data-href="#" className="instagram_gallery_image gallery_image_instagram10509608261653366570" rel="gallery" href="#">
                                            <img className="image_users" src={window.location.origin + "/assets/instagram/insta3.jpg"} title="insta1" alt="insta3" />
                                        </a>
                                    </div>
                                    <div className="img_users">
                                        <a title="insta1" data-href="#" className="instagram_gallery_image gallery_image_instagram10509608261653366570" rel="gallery" href="#">
                                            <img className="image_users" src={window.location.origin + "/assets/instagram/insta1.jpg"} title="insta1" alt="insta1" />
                                        </a>
                                    </div>
                                    <div className="img_users">
                                        <a title="insta1" data-href="#" className="instagram_gallery_image gallery_image_instagram10509608261653366570" rel="gallery" href="#">
                                            <img className="image_users" src={window.location.origin + "/assets/instagram/insta2.jpg"} title="insta1" alt="insta2" />
                                        </a>
                                    </div>
                                    <div className="img_users">
                                        <a title="insta1" data-href="#" className="instagram_gallery_image gallery_image_instagram10509608261653366570" rel="gallery" href="#">
                                            <img className="image_users" src={window.location.origin + "/assets/instagram/insta3.jpg"} title="insta1" alt="insta3" />
                                        </a>
                                    </div>
                                    <div className="img_users">
                                        <a title="insta1" data-href="#" className="instagram_gallery_image gallery_image_instagram10509608261653366570" rel="gallery" href="#">
                                            <img className="image_users" src={window.location.origin + "/assets/instagram/insta4.jpg"} title="insta1" alt="insta4" />
                                        </a>
                                    </div>
                                    <div className="img_users">
                                        <a title="insta1" data-href="#" className="instagram_gallery_image gallery_image_instagram10509608261653366570" rel="gallery" href="#">
                                            <img className="image_users" src={window.location.origin + "/assets/instagram/insta5.jpg"} title="insta1" alt="insta5" />
                                        </a>
                                    </div>
                                    <div className="img_users">
                                        <a title="insta1" data-href="#" className="instagram_gallery_image gallery_image_instagram10509608261653366570" rel="gallery" href="#">
                                            <img className="image_users" src={window.location.origin + "/assets/instagram/insta6.jpg"} title="insta1" alt="insta6" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="row">
                        <div className="bottom-footer">
                            <div className="col-md-7  col-sm-7 copyright">
                                <span className="copyrights">© 2022 - 2022. All Rights Reserved. Designed by <Link to="/" className="cname">SkinRange</Link></span>
                            </div>
                            <div className="col-md-5 col-sm-5 payment">
                                <img src={window.location.origin + "/assets/payment/payment.png"} alt="imgpayment" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer