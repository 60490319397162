import React, { useState, useEffect } from 'react'
import Helmet from 'react-helmet'
import axios from 'axios';
import Navbar from '../../components/navbar/Navbar'
import Topbar from '../../components/topbar/Topbar'
import Footer from '../footer/Footer'
import "./otp.css";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'

const Otp = () => {
    const [otp, setOTP] = useState()
    const mobile = localStorage.getItem("usermobile")
    const navigate = useNavigate()

    useEffect(() =>{
        const userdata = localStorage.getItem('userdata');
        if(userdata){
            navigate("/user-dashboard")
        }
    });

    const handleOTPSubmit = async (e) => {
        e.preventDefault();
        if (otp) {
            try {
                const matchedotp = await axios({
                    method:"POST",
                    url:"https://backendsk.skinrange.com/api/apirmatched-user-mobile-top",
                    data:{mobile:mobile, otp:otp},
                    headers: { "Content-Type": "multipart/form-data" },
                })
                if (matchedotp.data.status === "success") {
                    localStorage.setItem("userdata",JSON.stringify(matchedotp.data.userdata))
                    toast.success("Login has been successfully!!")
                    navigate("/user-dashboard")
                }
                else{
                    toast.error("Invalid mobile OTP!!")
                }
            } 
            catch (error) {
                toast.error("Unable to matched mobile OTP!!")
            }
        }
        else {
            toast.error("OTP field is required!!")
        }
    }
    return (
        <div>
            <Helmet>
                <title>Skinrange::Login</title>
            </Helmet>
            <Topbar />
            <Navbar />
            <section className="inner-section single-banner" style={{ backgroundImage: `url("assets/offer/offer-middle-banner.jpg")` }}>
                <div className="container">
                    <h2>Mobile OTP</h2>
                </div>
            </section>
            <section className="user-form-part">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-sm-5 col-md-6 col-lg-6 col-xl-5">
                            <div className="user-form-card">
                                <div className="user-form-title">
                                    <h2>User Mobile OTP</h2>
                                </div>
                                <div className="user-form-group">
                                    <form className="user-form" method="post" onSubmit={handleOTPSubmit} noValidate="novalidate">
                                        <div className="form-group">
                                            <label htmlFor="otp">Mobile OTP:</label>
                                            <input type="tel" name="otp" id="otp" className="form-control" placeholder="Enter your Mobile OTP" onChange={e => setOTP(e.target.value)} />
                                        </div>
                                        <div className="form-button">
                                            <button className="register">Send</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Otp