import React from 'react'
import Helmet from 'react-helmet';
import Navbar from '../../components/navbar/Navbar';
import Topbar from '../../components/topbar/Topbar';
import "./privacypolicy.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import Footer from '../footer/Footer';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

const PrivacyPolicy = () => {
    const [ppolicy, setPpolicy] = useState([])
    useEffect(()=>{
        getPpolicy()
    }, []);
    const getPpolicy = async () => {
        try {
            const responsepolicy = await axios({
                method:"GET",
                url:"/apiprivacypolicy",
                header:{"Content-Type" : "multipart/form-data"}
            })
            if(responsepolicy.data.status === "success"){
                setPpolicy(...[responsepolicy.data.pdata])
            }
            else{
                toast.error("Unable to load Privacy Policy Data")
            }
        } 
        catch (error) {
            toast.error("Check Connection")
        }
    }
    return (
        <>
            <Helmet>
                <title>Skinrange::Privacy and Policy</title>
            </Helmet>
            <Topbar />
            <Navbar />
            <section className="inner-section single-banner" style={{backgroundImage: `url("assets/offer/offer-middle-banner.jpg")`}}>
                <div className="container">
                    <h2>Privacy & Policy</h2>
                </div>
            </section>
            <section className="inner-section privacy-part">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-2 mb-4">
                            <div className="mt-4" data-bs-spy="scroll" data-bs-target="#scrollspy" data-bs-offset="0" tabIndex="0">
                                <p><div dangerouslySetInnerHTML={{__html: ppolicy.policy}} /></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default PrivacyPolicy