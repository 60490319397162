import Topbar from "../../components/topbar/Topbar";
import Navbar from "../../components/navbar/Navbar";
import "./home.css";
import {Helmet} from "react-helmet";
import Banner from "../../components/banner/Banner";
import BestSeller from "../../components/bestseller/BestSeller";
import HomeCategory from "../../components/homecategory/HomeCategory";
import Service from "../../components/service/Service";
import Footer from "../footer/Footer";
import Offermiddle from "../../components/offer/offermiddle/Offermiddle";
import Fsection2 from '../../components/footersection/fsection2/Fsection2';
import Deals from "../../components/deals/Deals";
import MiddleSection from "../../components/middlesection/MiddleSection";
import TopSelling from "../../components/topselling/TopSelling";
import Brand from "../../components/brand/Brand";

const home = () => {
    return (
        <div>
            <Helmet>
                <title>Skinrange</title>
            </Helmet>
            <Topbar />
            <Navbar />
            <Banner />
            <Service />
            <HomeCategory />
            <MiddleSection />
            <BestSeller />
            <Offermiddle />
            <Deals />
            <TopSelling />
            <Brand />
            <Fsection2 />
            <Footer />
        </div>
    )
}

export default home
