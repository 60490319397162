import { useState, useEffect } from "react"
import "./banner.css";
import Carousel from 'react-bootstrap/Carousel'
import axios from "axios";
import { toast } from "react-toastify";

const Banner = () => {
    const [banner, setBanner] = useState([]);
    const baseUrl = "https://backendsk.skinrange.com/assets/banner/";
    useEffect(() => {
        getBanner()
    }, []);

    const getBanner = async () => {
        try {
            const bannerresponse = await axios({
                method: "GET",
                url: "https://backendsk.skinrange.com/api/apibanner",
                header: { "Content-Type": "multipart/form-data" }
            })
            if (bannerresponse.status === 200) {
                setBanner(...[bannerresponse.data.bannerdata])
            }
            else {
                toast.error("Unable to fetched banner!!")
            }
        }
        catch (error) {
            toast.error("Check Connection!!")
        }
    }

    return (
        <Carousel>
            {
                banner?.map((banner) => (
                    <Carousel.Item key={banner}>
                        <img className="d-block w-100" src={baseUrl + banner.banner} alt="Second slide" />
                    </Carousel.Item>
                ))}
        </Carousel>
    )
}

export default Banner
