import React from 'react'
import Helmet from 'react-helmet';
import Navbar from '../../components/navbar/Navbar';
import Topbar from '../../components/topbar/Topbar';
import Footer from '../footer/Footer';
import "./blogdetail.css";
import "./blog.css";

const BlogDetail = () => {
    return (
        <>
            <div className="blogdetail">
                <Helmet>
                    <title>Skinrange::Blog Details</title>
                </Helmet>
                <Topbar />
                <Navbar />
                <section className="inner-section single-banner" style={{backgroundImage: `url('/assets/offer/offer-middle-banner.jpg')`}}>
                    <div className="container">
                        <h2>Blog Details</h2>
                    </div>
                </section>
                <section className="inner-section blog-details-part mt-4">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-9">
                                <article className="blog-details">
                                    <a className="blog-details-thumb" href="#">
                                        <img src={window.location.origin + "/assets/blog/01.jpg"} alt="blog" />
                                    </a>
                                    <div className="blog-details-content">
                                        <ul className="blog-details-meta">
                                            <li><i className="fa fa-calendar"></i><span>Posted on :- february 02, 2021</span></li>
                                            <li><i className="fa fa-user"></i><span>Posted By :- Miron mahmud</span></li>
                                        </ul>
                                        <h2 className="blog-details-title">Adipisicing elit. Earum beatae, eius voluptates lorem ipsum dolor sit amet consectetur.</h2>
                                        <p className="blog-details-desc">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Molestias repellendus laudantium at optio ex, magnam illo ducimus placeat voluptates officiis molestiae ratione assumenda dignissimos est id hic veritatis. Aspernatur aliquam praesentium officiis pariatur velit nesciunt debitis odio, dolores harum, quidem nobis enim. Incidunt quia doloremque ipsam optio repudiandae non ipsa.</p>
                                        <p className="blog-details-desc">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptas est nisi ut eius quod? Libero, labore. Omnis maiores at, incidunt odit commodi, possimus voluptas porro sunt beatae laboriosam provident. Id, sit laborum accusantium quia ad aut eaque obcaecati eos, <a href="#">adipisci</a>molestias quasi nostrum harum nihil, rerum totam doloribus perspiciatis quos reiciendis voluptatibus. Eos numquam eaque sed nemo illo optio quos facere, at alias! Doloremque nemo, porro neque magnam voluptatem harum at quidem. Porro asperiores facere veritatis consequatur. Minus amet error nam laudantium omnis modi molestias, ex eveniet sed non. Veniam quas aspernatur quisquam aut quis ea iusto, officia eos sunt vel, voluptatibus incidunt ut eius nesciunt perspiciatis modi reiciendis fugit alias autem, dolorum mollitia esse earum? Doloribus illum culpa eligendi!</p>
                                        
                                        <p className="blog-details-desc">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Rerum eligendi asperiores id, doloribus quasi assumenda perspiciatis dicta vero quidem repellendus qui dolore et recusandae deserunt at perferendis dignissimos provident, similique quos dolor dolorem ducimus modi nisi quis? Hic error, officia, porro officiis explicabo consequatur magnam nostrum itaque minima expedita ex assumenda dolorum consequuntur molestias, blanditiis dignissimos vel quia. Aliquam neque, omnis culpa quaerat dolor quibusdam laborum consequuntur tenetur sit excepturi adipisci, ducimus molestiae exercitationem perspiciatis, nobis eum magnam explicabo.</p>
                                    </div>
                                </article>
                            </div>
                            <div className="col-md-7 col-lg-3">
                                <div className="blog-widget">
                                    <h3 className="blog-widget-title">Recent Post</h3>
                                    <ul className="blog-widget-feed">
                                        <li><a className="blog-widget-media" href="#">
                                            <img src={window.location.origin + "/assets/blog/01.jpg"} alt="blog-widget" /></a>
                                            <h6 className="blog-widget-text"><a href="#">Lorem ipsum dolor sit amet consectetur</a><span>february 02, 2021</span></h6>
                                        </li>
                                        <li><a className="blog-widget-media" href="#">
                                            <img src={window.location.origin + "/assets/blog/02.jpg"} alt="blog-widget" /></a>
                                            <h6 className="blog-widget-text"><a href="#">Lorem ipsum dolor sit amet consectetur</a><span>february 02, 2021</span></h6>
                                        </li>
                                        <li><a className="blog-widget-media" href="#">
                                            <img src={window.location.origin + "/assets/blog/03.jpg"} alt="blog-widget" /></a>
                                            <h6 className="blog-widget-text"><a href="#">Lorem ipsum dolor sit amet consectetur</a><span>february 02, 2021</span></h6>
                                        </li>
                                        <li><a className="blog-widget-media" href="#">
                                            <img src={window.location.origin + "/assets/blog/04.jpg"} alt="blog-widget" /></a>
                                            <h6 className="blog-widget-text"><a href="#">Lorem ipsum dolor sit amet consectetur</a><span>february 02, 2021</span></h6>
                                        </li>
                                        <li><a className="blog-widget-media" href="#">
                                            <img src={window.location.origin + "/assets/blog/05.jpg"} alt="blog-widget" /></a>
                                            <h6 className="blog-widget-text"><a href="#">Lorem ipsum dolor sit amet consectetur</a><span>february 02, 2021</span></h6>
                                        </li>
                                    </ul>
                                </div>
                                <div className="blog-widget">
                                    <h3 className="blog-widget-title">follow us</h3>
                                    <ul className="blog-widget-social">
                                        <li><a href="#" className="fab fa-facebook-f"></a></li>
                                        <li><a href="#" className="fab fa-twitter"></a></li>
                                        <li><a href="#" className="fab fa-pinterest"></a></li>
                                        <li><a href="#" className="fab fa-linkedin"></a></li>
                                        <li><a href="#" className="fab fa-instagram"></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </>
    )
}

export default BlogDetail