import React, { useState, useEffect } from 'react'
import Helmet from 'react-helmet'
import Navbar from '../../components/navbar/Navbar'
import Topbar from '../../components/topbar/Topbar'
import Footer from '../footer/Footer'
import "./product.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStarHalfAlt } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { useCart } from "react-use-cart";

const Product = () => {
    const { addItem } = useCart();
    const addToCart = (product) => {
        addItem(product);
        toast.success("Product has been added in your cart!");
    }

    const [product, setProduct] = useState([])
    const baseUrl = "https://backendsk.skinrange.com/assets/product/";
    useEffect(() => {
        getProduct()
    }, []);
    const getProduct = async (catid) => {
        if(catid){
            if(catid === "all"){
                try {
                    const productresponse = await axios({
                        method:"GET",
                        url:"https://backendsk.skinrange.com/api/apiproduct",
                    })
                    if(productresponse.data.status === "success"){
                        setProduct(...[productresponse.data.productdata])
                    }
                    else{
                        toast.error("Unable to load product data")
                    }
                } 
                catch (error) {
                    toast.error("Check Connection")
                }
            }
            else{
                try {
                    const response = await axios({
                        method:"GET",
                        url:`https://backendsk.skinrange.com/api/apiproduct-by-category/${catid}`,
                    })
                    if(response.data.status === "success"){
                        setProduct(...[response.data.productdata])
                    }
                    else{
                        toast.error("Data not founds!")
                    }
                } 
                catch (error) {
                    toast.error("Check Connection")
                }
            }
        }
        else{
            try {
                const productresponse = await axios({
                    method:"GET",
                    url:"https://backendsk.skinrange.com/api/apiproduct",
                    header:{"Content-Type":"multipart/form-data"}
                })
                if(productresponse.data.status === "success"){
                    setProduct(...[productresponse.data.productdata])
                }
                else{
                    toast.error("Unable to load product data")
                }
            } 
            catch (error) {
                toast.error("Check Connection")
            }
        }
    }

    const [category, setCategory] = useState([])
    useEffect(()=>{
        getCategory()
    },[])
    const getCategory = async() => {
        try {
            const responecategory = await axios({
                method:"GET",
                url:"https://backendsk.skinrange.com/api/apicategory",
            })
            if(responecategory.data.status === "success"){
                setCategory(...[responecategory.data.categorydata])
            }
            else{
                toast.error("Unable to fetched Category data!")
            }
        } 
        catch (error) {
            toast.error("Check Connection!")
        }
    }
    return (
        <div>
            <Helmet>
                <title>Skinrange::Product</title>
            </Helmet>
            <Topbar />
            <Navbar />
            <section className="inner-section single-banner" style={{backgroundImage: `url("assets/offer/offer-middle-banner.jpg")`}}>
                <div className="container">
                    <h2>Product</h2>
                </div>
            </section>
            <section className="inner-section shop-part mt-4">
                <div className="container">
                    <div className="row content-reverse">
                        <div className="col-lg-3">
                            <div className="shop-widget">
                                <h6 className="shop-widget-title">Filter by category</h6>
                                <form id="productShorting" method="post">
                                    <ul className="shop-widget-list shop-widget-scroll" style={{overflowY:"unset",height:"unset"}}>
                                        {category?.map((category)=>(
                                            <li>
                                                <div className="shop-widget-content">
                                                    <input onChange={() =>getProduct(category.category_id)} type="radio" name="fcat" value={category.category_id} />
                                                    <label for="fcat">{category.cat_name}</label>
                                                </div>
                                            </li>
                                        ))}
                                        <li>
                                            <div className="shop-widget-content">
                                                <input onChange={() =>getProduct('all')} type="radio" name="fcat" value='all' />
                                                <label for="brand1">All</label>
                                            </div>
                                        </li>
                                    </ul>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
                                {product.map((product)=>(
                                    <div className="col">
                                        <div className="product-card">
                                            <div className="product-media">
                                                <Link className="product-image" to="/product">
                                                    <img src={baseUrl + product.image} alt={product.image} />
                                                </Link>
                                            </div>
                                            <div className="product-content">
                                                <h6 className="product-name">
                                                    <Link to="/product">{product.name}</Link>
                                                </h6>
                                                <div className="product-rating">
                                                    <span className="product-ratings">4.0 <FontAwesomeIcon className="active" icon={faStarHalfAlt} /></span>
                                                </div>
                                                <h6 className="product-price">
                                                    <del>₹ {product.product_cut_price}</del>
                                                    <span>₹ {product.price}</span>
                                                </h6>
                                                <div className="add-button">
                                                    <button onClick={() => addToCart(product)} className="addtocart-button addToCart" title="Add to Cart"><i className="fas fa-shopping-basket"></i><span>Add To Cart</span></button> 
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                ))}                                           
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Product